import React, { useEffect, useState } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import ArrowDown from 'src/assets/images/Svgs/Polygon.svg';
import { UserCircleFilledIcon } from 'src/assets/images/Svgs/svg';
import styles from 'src/components/AccountStatus/signUpBtn.module.scss';
import * as CONSTANT from 'src/constants';
import useGetCZDollarsBalance from 'src/react-query-hooks/Loyalty/useGetCZDollarsBalance';
import useGetUserPointsEarned from 'src/react-query-hooks/Loyalty/useGetUserPointsEarned';
import useProfile from 'src/react-query-hooks/useProfile';
import { useAppDispatch } from 'src/redux/store/store';
import { customerBusinessService } from 'src/services';

import { updateDefaultGiftCard } from '../../../redux/slices/checkoutSlice';
import { logout } from '../redux/actions';
interface IUserNameProps {
  userId: string | number;
  userType?: string;
}

const UserName: React.FC<IUserNameProps> = ({ userId, userType }) => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { data: userProfileData } = useProfile(userId, userType);
  const { data: czDollarsBalance } = useGetCZDollarsBalance(
    typeof userId === 'number',
  );
  const { data: userPointsEarned } = useGetUserPointsEarned(
    typeof userId === 'number',
  );
  const userDispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const showModalHandler = () => {
    setShowModal(true);
  };
  const closeModalHandler = () => {
    setShowModal(false);
  };
  const handleLogout = async () => {
    setLoading(true);
    await customerBusinessService.logout();
    logout(userDispatch);
    setLoading(false);
    queryClient.removeQueries();
    history.push('/');
  };
  useEffect(() => {
    if (czDollarsBalance?.id) {
      userDispatch(updateDefaultGiftCard(czDollarsBalance));
    }
  }, [czDollarsBalance]);
  return (
    <>
      <div
        className={`${styles.app_user_links} app_user_links d-none d-md-flex align-items-end`}
      >
        <Dropdown className={styles.typeLinkDropdown}>
          <Dropdown.Toggle
            variant="success"
            id="dropdown-basic"
            className={`${styles.dropDownToggle} d-inline-flex align-items-center`}
          >
            <UserCircleFilledIcon />
            {userProfileData && (
              <div className="d-flex flex-column align-items-start ms-1">
                <span className="text-uppercase f-s12 cz-lh-16 ls-2 ">
                  {' '}
                  Welcome,
                </span>{' '}
                <span className={`f-s16 cz-lh-16 ls-1 ${styles.userName} `}>
                  {' '}
                  {userProfileData?.first_name?.toUpperCase()}
                </span>
              </div>
            )}
            {userProfileData && (
              <img
                src={ArrowDown}
                alt="ArrowDown"
                className={styles.arrowDown}
              />
            )}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <p className="h-18 font-Cls text-center overflow-hidden text-break text-capitalize mb-1">
              {userProfileData?.full_name}
            </p>
            <Dropdown.Item
              as={Link}
              to={CONSTANT.ROUTE_LOYALTY}
              className="d-flex align-items-center justify-content-center clr-red"
            >
              <div className="d-flex gap-4 f-s14">
                <div className={styles.dollarBalance}>
                  <span>{czDollarsBalance?.title} </span>
                  CZ Dollars
                </div>
                <div className={styles.dollarBalance}>
                  <span>{userPointsEarned?.points} </span>
                  Loyalty Points
                  <img
                    src={ArrowDown}
                    alt="ArrowDown"
                    className={styles.arowRight}
                  />
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              to={CONSTANT.ROUTE_PROFILE_DETAILS}
              className="d-flex align-items-center justify-content-center clr-red"
            >
              <img
                src={`${CONSTANT.s3BaseUrl}/images/icons/UserCircle.svg`}
                className="img-fluid me-1 "
                alt="icon"
              />
              Profile & Preferences
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              to={CONSTANT.ROUTE_LOYALTY}
              className="d-flex align-items-center justify-content-center clr-red"
            >
              Loyalty & Rewards
            </Dropdown.Item>
            <div className="text-center">
              <button
                type="button"
                className="btn-small dark-grey f-s14 mt-4 mb-4"
                onClick={handleLogout}
                disabled={loading}
              >
                SIGN OUT
              </button>
            </div>
            <div
              className={`${styles.cmsPages} d-flex justify-content-center fs-14 align-items-center`}
            >
              <Dropdown.Item as={Link} to={CONSTANT.TERMS_ROUTE}>
                Terms and Conditions
              </Dropdown.Item>
              <span className="d-inline-block px-2">and</span>
              <Dropdown.Item as={Link} to={CONSTANT.PRIVACY_POLICY_ROUTE}>
                Privacy Policy
              </Dropdown.Item>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <Modal
        show={showModal}
        onHide={closeModalHandler}
        centered
        backdropClassName="backdrop-responsive-100vmax"
        className="theme-modal-style app_container_wrap no-padding border-radius-15 no-border xs-modal responsive-100vmax receipt-modal"
      >
        <Modal.Header
          className="pt-3 pt-md-3 mt-md-2 ps-0 pb-2"
          id="close_button2"
        >
          <button
            type="button"
            className="btn btn-custom back-arrow-btn f-s14 px-0 mb-0 position-fixed"
            onClick={closeModalHandler}
          >
            Back
          </button>
        </Modal.Header>
      </Modal>
    </>
  );
};

export default UserName;
