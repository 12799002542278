import React from 'react';

import styleClasses from '../../../../containers/Modifiers/order.module.scss';
import { ILabels } from '../../../../models/card';

const Labels: React.FC<ILabels> = (props) => {
  const {
    isItSubstitute,
    priceAlternateLabel,
    additionalPrice = 0,
    calories,
    price,
    remainingBase,
    showAdditionalPrice = false,
    count = 0,
    itemBase = 0,
  } = props;

  const showPriceAdjustment = () => {
    if (price && price > 0) {
      return `+$${price.toFixed(2)}`;
    } else {
      return isItSubstitute ? 'Substitute' : '';
    }
  };

  // const showLabel = () => {
  //   if (priceAlternateLabel) {
  //     return <span >{priceAlternateLabel} </span>;
  //   } else {
  //     return showPriceAdjustment()
  //   }
  // }

  const ExtraPrice = () => {
    if (remainingBase > 0) {
      if (price <= remainingBase) {
        return 0;
      } else if (price - remainingBase > 0) {
        return price - remainingBase;
      }
    } else {
      return price;
    }
  };

  // const showPrice = ExtraPrice()

  // const additionalChargingPrice = showAdditionalPrice && count === 0 && showPrice > 0 ? true : false

  return (
    <h6
      className={`${styleClasses.item_detals} f-s10 font-rale d-flex justify-content-between mb-0 lh-base text-nowrap`}
    >
      {/* {calories > 0 && `${calories} cal`} */}

      {/* Below code is for testing purpose don't remove */}
      {/* {
        showAdditionalPrice ?
        <>
          <br/>
          {itemBase} itemBase
          <br/>
          {remainingBase} groupbase
          <br/>
          {price} price
          <br/>
          {showPrice && showPrice.toFixed(2)} next Price
        </>
        :null
      }
       */}
      {
        additionalPrice > 0 && (
          <>
            <span className="text-danger font-Visby-cf f-w5 w-100 text-end ">
              +${additionalPrice.toFixed(2)}
            </span>
          </>
        )
        // ? Remove all albels other then charged price, [removeing here instead of all files because may be tomorrow they ask for display it back again so we just have to uncomment this in that case]
        // : (
        //   <span className='text-end d-block w-100 '>
        //     {showLabel()}
        //     {/* {showAdditionalPrice && count === 0 && price > itemBase && <span className="text-danger font-Visby-cf f-w5">+${(price - itemBase).toFixed(2)}</span>} */}

        //     {/* Below code is for scenerio checking (2nd use case) ... don't remove */}
        //     {/* {additionalChargingPrice &&  <span className="text-danger font-Visby-cf f-w5">+${showPrice && showPrice.toFixed(2)}</span>} */}
        //   </span>
        // )
      }
    </h6>
  );
};

export default Labels;
