import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Accordion } from 'react-bootstrap';
import { DO_NOT_SHOW } from 'src/constants';
import styleClasses from '../../../../src/Features/ItemCustomization/ItemCustomization.module.scss';
import ItemModifierGroups from './ModifierGroups/ItemModifierGroups';

const ModifierGroups: React.FC<any> = (props) => {
  const {
    modifierGroups,
    selectedAddOns,
    selectedCore,
    isLimitExceed,
    handleClick,
    isItEdit,
    cardColSize,
    isBoxLunch,
    Item,
    isPackege = false,
  } = props;
  const showGroup = (ingredients) => {
    if (ingredients?.name?.toLowerCase().includes(DO_NOT_SHOW)) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <>
      {modifierGroups &&
        modifierGroups.map((ingredients, index) => {
          const label = ingredients?.label?.toLowerCase();
          return (
            <>
              <React.Fragment>
                {ingredients?.modifiers_groups_modifiers?.length > 0 ? (
                  <React.Fragment key={ingredients?.id}>
                    {showGroup(ingredients) ? (
                      <Container
                        fluid
                        className={`${styleClasses.try2_all_dressing} modifiersContainer`}
                      >
                        <Accordion
                          defaultActiveKey={isBoxLunch ? '0' : ''}
                          className="modifiers-accordions-wrapper"
                          // alwaysOpen
                        >
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>
                              <span className={`d-block text-capitalize`}>
                                {label}
                              </span>
                            </Accordion.Header>
                            <Accordion.Body>
                              <Row>
                                <ItemModifierGroups
                                  ingredients={ingredients}
                                  selectedAddOns={selectedAddOns}
                                  selectedCore={selectedCore}
                                  handleClick={handleClick}
                                  isLimitExceed={isLimitExceed}
                                  isItEdit={isItEdit}
                                  itemModifierItemModalWidth={''}
                                  cardColSize={cardColSize}
                                  Item={Item}
                                />
                              </Row>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Container>
                    ) : null}
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            </>
          );
        })}
    </>
  );
};

export default ModifierGroups;
