import React, { useEffect, useRef, useState } from 'react';
import { MinusIcon, PlusIcon } from 'src/assets/images/Svgs/svg';
import { FULL_SIZE } from 'src/constants';
import ItemPrice from 'src/Features/ItemCustomization/ItemPrice';
import {
  isItemModifiersUnavailable,
  notAvailableAddedModifiers,
} from 'src/helper/helperMethods';
import { getSizeName, getSizeNameInCaseOfHalf } from 'src/helper/utils';
import { awsAssetsFetcher } from 'src/utils/awsAssetsFetcher';

import { cartItem } from '../../../models/cart.model';
import styleClasses from '../order.module.scss';

interface IOrderDetailsProps {
  Titlehide?: any;
  Imagehide?: any;
  Items?: any;
  isOrdered?: boolean;
  ScrollCard?: any;
  Buttonspace?: any;
  styleHidetitle?: string;
}

const OrderDetails = (props: IOrderDetailsProps) => {
  const {
    Titlehide,
    Imagehide,
    Items,
    isOrdered = false,
    styleHidetitle,
  } = props;
  const [cardStates, setCardStates] = useState(
    Items.map(() => ({
      isShowMoreNames: false,
      isShowMoreInstruction: false,
    })),
  );
  const { ScrollCard, Buttonspace } = props;

  const itemHeight = useRef(null);
  const instructionHeight = useRef(null);
  const [nameDivHeight, setNameDivHeight] = useState<number>(0);
  const [instructionDivHeight, setInstructionDivHeight] = useState<number>(0);

  const [isShowLess, setIsShowLess] = useState<boolean>(true);
  const [loadCount, setLoadCount] = useState(5);
  const showMoreHandler = (index) => {
    setCardStates((prevStates) => {
      const updatedStates = [...prevStates];
      updatedStates[index].isShowMoreNames =
        !updatedStates[index].isShowMoreNames;
      return updatedStates;
    });
  };

  const showMoreInstructionHandler = (index) => {
    setCardStates((prevStates) => {
      const updatedStates = [...prevStates];
      updatedStates[index].isShowMoreInstruction =
        !updatedStates[index].isShowMoreInstruction;
      return updatedStates;
    });
  };

  const displayPrice = (item: cartItem, itemQuantity: number) => {
    return parseFloat((item.gross_price * itemQuantity).toFixed(2));
  };
  const discountedPrice = (item: cartItem, itemQuantity: number) => {
    return parseFloat(
      (
        item.gross_price * itemQuantity -
        (item.discount_amount || item.discount || item.base_price)
      ).toFixed(2),
    );
  };

  useEffect(() => {
    if (isShowLess) {
      setLoadCount(5);
    } else {
      setLoadCount(Items.length);
    }
  }, [isShowLess]);

  useEffect(() => {
    if (
      (!nameDivHeight || nameDivHeight <= 0) &&
      itemHeight?.current?.scrollHeight
    ) {
      setNameDivHeight(itemHeight?.current?.scrollHeight);
    }
  }, [Items?.recipient_name]);

  useEffect(() => {
    if (
      (!instructionDivHeight || instructionDivHeight <= 0) &&
      instructionHeight?.current?.scrollHeight
    ) {
      setInstructionDivHeight(instructionHeight?.current?.scrollHeight);
    }
  }, [Items?.special_instructions]);

  const itemSize = (item) => {
    const item_size = item?.item_size ?? item?.size;
    return item_size === FULL_SIZE.id
      ? getSizeName(item?.category_name).upper_case
      : getSizeNameInCaseOfHalf(item?.category_name, item_size);
  };

  return (
    <>
      <h2
        className={`f-s22 text-start cz-lh-22 text-uppercase font-Cls fw-normal f-sm-s16 pt-2 f-w5 mb-4 ${styleHidetitle}`}
      >
        order Details
      </h2>
      <div className={`mt-md-3 ${styleClasses.order_parent} ${ScrollCard}`}>
        {Items?.slice(0, loadCount).map((item, index) => (
          <React.Fragment key={item.id}>
            <div
              className={`${styleClasses.order_details_card_wrapper} ${
                !item.available && !isOrdered ? styleClasses.detail_error : ' '
              }`}
            >
              <div className="d-flex justify-content-between align-items-center">
                <div
                  className={`d-flex align-items-center ${styleClasses.order_details_img}`}
                >
                  <div className="d-none d-md-block">
                    <img
                      className={`${Imagehide} ${styleClasses.checkCardImg}`}
                      alt="image not found"
                      src={awsAssetsFetcher(
                        `Catering/Items/${item?.item_id}/item-${item?.item_id}`,
                        'jpeg',
                      )}
                    />
                  </div>

                  <div className="ms-md-3 ">
                    <h5 className="f-s18 f-sm-s14 text-start font-Cls text-capitalize mb-0 f-w5">
                      {item.name}
                    </h5>
                    <div className="f-s14 text-start font-Visby-cf clr-text-grey ">
                      {item.quantity} x{' '}
                      {item?.single_size ? null : `${itemSize(item)} Order`}
                    </div>
                    {/* <div className={styleClasses.nameSection}>
                      <span
                        className={`${
                          showMoreNames && styleClasses.nameText
                        } d-block f-s14 font-Visby-cf f-w6 mt-1 text-capitalize text-start me-2}`}
                        ref={itemNameRef}
                      >
                        {item?.recipient_name}
                        <div className="d-flex justify-content-start">
                          {divHeight >= 21 && (
                            <button onClick={showMoreHandler}>
                              {' '}
                              {showMoreNames ? 'show more' : 'show less'}
                            </button>
                          )}
                        </div>
                      </span>
                    </div> */}
                    <div className={styleClasses.nameSection}>
                      <span
                        className={`d-block f-s14 font-Visby-cf f-w6 mt-1 text-start text-capitalize ${
                          // showMoreNames && styleClasses.nameText
                          cardStates[index].isShowMoreNames &&
                          styleClasses.nameText
                        }`}
                        ref={itemHeight}
                      >
                        {item?.recipient_name
                          ?.split(',')
                          ?.map((name, index) => {
                            return (
                              <span className={`f-w6 mb-0`}>
                                {name}
                                {index <
                                item?.recipient_name?.split(',')?.length - 1
                                  ? ','
                                  : ''}{' '}
                              </span>
                            );
                          })}
                      </span>
                      <div className="d-flex justify-content-start">
                        {nameDivHeight >= 42 && (
                          <button onClick={() => showMoreHandler(index)}>
                            {cardStates[index].isShowMoreNames
                              ? 'show more'
                              : 'show less'}
                          </button>
                        )}
                      </div>
                    </div>
                    <div className={styleClasses.nameSection}>
                      <span
                        className={`d-block f-s14 font-Visby-cf f-w6 mt-1 text-start text-capitalize ${
                          cardStates[index].isShowMoreInstruction &&
                          styleClasses.nameText
                        }`}
                        ref={instructionHeight}
                      >
                        {item?.special_instructions}
                      </span>
                      <div className="d-flex justify-content-start">
                        {instructionDivHeight > 42 && (
                          <button
                            onClick={() => showMoreInstructionHandler(index)}
                          >
                            {cardStates[index].isShowMoreInstruction
                              ? 'show more'
                              : 'show less'}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center d-none d-md-block ">
                  {/*<p className="f-s14 clr-text-grey mb-0 font-Visby-cf">Total</p>*/}
                  {/*<p className="font-Visby-cf f-s16 mb-0 f-w6">${item.display_price}</p>*/}
                  <ItemPrice
                    price={displayPrice(item, item.quantity)}
                    isRedeemed={
                      !!item.reward_redeemed || !!item.redemption_by_points
                    }
                    discountPrice={discountedPrice(item, item.quantity)}
                  />
                </div>
              </div>
            </div>
            {!item?.available && !isOrdered && (
              <div
                className={`w-100 text-center mx-auto px-2 px-md-0 font-rale f-s14 clr-dark-red ${styleClasses.error_msg}`}
              >
                This item is not available.
                <br />
                Please remove from your order.
              </div>
            )}
            {isItemModifiersUnavailable(item) && !isOrdered && (
              <div
                className={`w-md-50 w-100 text-center px-2 px-md-0 mx-auto font-rale f-s14 clr-dark-red ${styleClasses.error_msg}`}
              >
                Unfortunately, we are currently out of{' '}
                {notAvailableAddedModifiers(item)?.join(', ')} Today.
                <br />
                Please remove from your order.
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
      {Items?.length > 5 && (
        <div
          className={`${styleClasses.less_more_btn} ${Buttonspace} bg-clr-white-clr py-2 mb-3`}
        >
          <button
            className="btn btn-custom d-flex align-items-center gap-2 justify-content-center py-2 font-Visby-cf f-s14 w-100"
            onClick={() => {
              setIsShowLess(!isShowLess);
            }}
          >
            {isShowLess ? (
              <PlusIcon className={undefined} />
            ) : (
              <MinusIcon className={undefined} />
            )}
            {isShowLess ? 'View More' : 'View Less'}
          </button>
        </div>
      )}
      {/* <div className={`${Titlehide} shadow-divider d-md-none mt-4`} /> */}
    </>
  );
};

export default OrderDetails;
