import { useQuery } from 'react-query';

import { customerBusinessService } from '../../services';

const getOffers = async () => {
  const response = await customerBusinessService.getOffers();
  return response.data.data;
};

export default function useGetOffers(
  enabled: boolean,
  moduleName: 'CartModal' | 'RewardsPage',
) {
  return useQuery<any>(['offers', moduleName], () => getOffers(), {
    cacheTime: 0,
    enabled: enabled,
  });
}
