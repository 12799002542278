import { useEffect, useState } from 'react';
import _clonedeep from 'lodash.clonedeep';
import GIftCardForm from 'src/components/Forms/GiftCardForm';
import {
  appliedPaymentMethods,
  removeGiftCard,
  removePaymentCard,
} from 'src/helper/checkout/PaymentHelper';
import { Toast_Func } from 'src/helper/toast.helper';
import usePaymentMethods from 'src/hooks/usePaymentMethods';

import {
  GIFT_CARD,
  MAX_PAYMENT_LIMIT,
  PAYMENT_CARD,
  ROUTE_ADD_PAYMENT,
} from '../../../constants';
import { ICheckoutPayment, IGiftCard } from '../../../models/payment.model';
import {
  updateAmountOnSplit,
  updateGiftCard,
  updateGiftCardAmount,
  updateIsSplitPayment,
  updatePaymentCard,
  updatePaymentCardAmount,
} from '../../../redux/slices/checkoutSlice';
import {
  updateAmountOnSplitForTextToPayFlow,
  updateGiftCardAmountForTextToPayFlow,
  updateGiftCardForTextToPayFlow,
  updateIsSplitPaymentForTextToPayFlow,
  updatePaymentCardAmountForTextToPayFlow,
  updatePaymentMethodForTextToPayFlow,
} from '../../../redux/slices/addPayment/addPaymentSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/store/store';
import { paymentService } from '../../../services';
import styles from '../order.module.scss';

import PaymentModal from './PaymentModal';
import SelectedGiftCard from './SelectedGiftCard';
import SelectedPaymentCard from './SelectedPaymentCard';
import SplitPaymentToggle from './splitPaymentToggle';
import { getAddPaymentUser } from 'src/helper/helperMethods';
import { useLocation } from 'react-router';

interface ISplitPaymentMethodProps {
  checkout: any;
  backBTn?: string;
  backBtnHiden: string;
  totalAmount: number;
  handleCloseSplitPayment?: () => void;
  hideSplitPayments?: () => void;
  setOrderTippingAmount: (number) => void;
  paymentValidationMessage?: string;
  setPaymentInvalidMessage?: (string) => void;
  showGiftCard?: boolean;
}

const SplitPaymentMethod = (props: ISplitPaymentMethodProps) => {
  const {
    totalAmount,
    checkout,
    handleCloseSplitPayment,
    paymentValidationMessage,
    setOrderTippingAmount,
    setPaymentInvalidMessage,
    showGiftCard,
  } = props;

  const location = useLocation();
  const handlePaymentForAddPaymentUser =
    location.pathname.includes(ROUTE_ADD_PAYMENT);

  // const checkout = useAppSelector((state) => state.checkout);
  const fixedTotalAmount = Number(totalAmount.toFixed(2));
  const paymentMethods = checkout.paymentMethods;
  const addedCards = checkout.giftCard;
  const defaultGiftCard = checkout.defaultGiftCard;
  const checkoutDispatch = useAppDispatch();
  const addPaymentUser = getAddPaymentUser();
  const orderId = handlePaymentForAddPaymentUser
    ? addPaymentUser.order_id
    : useAppSelector((state) => state.cart.orderId);
  const [isInvalidAmount, setIsInvalidAmount] = useState<boolean>(false);
  const [addSecondSplitMethod, setAddSecondSplitMethod] =
    useState<boolean>(false);
  const [addPaymentCard, setAddPaymentCard] = useState<boolean>(false);
  const [addGiftCard, setAddGiftCard] = useState<boolean>(false);
  const allSelectedCards = appliedPaymentMethods(paymentMethods, addedCards);
  const [addedAmounts, setAddedAmounts] = useState<any[]>([]);
  const splitAmounts = checkout.isSplitPayment;
  const [isSplitPayment, setIsSplitPayment] = useState(splitAmounts);
  const [editCard, setEditCard] = useState(null);
  const authInfo = handlePaymentForAddPaymentUser
    ? useAppSelector((state) => state.addPayment.addPaymentUser)
    : useAppSelector((state) => state.user.user);
  const [replacePaymentCards, setReplacePaymentCards] =
    useState<boolean>(false);
  const [disableRemove, setDisableRemove] = useState<boolean>(false);
  const [replaceGiftCards, setReplaceGiftCards] = useState<boolean>(false);
  const [invalidAmountMessage, setInvalidAmountMessage] =
    useState<string>(null);
  const { data: paymentRecords = [], isFetching: recordFetching } =
    usePaymentMethods(authInfo.id, authInfo.type);
  const setSplitToggle = (isSplit: boolean) => {
    setIsSplitPayment(isSplit);
  };
  const removePayment = (payment: string) => {
    resetInvalid();
    const updatedPayments = removePaymentCard(paymentMethods, payment);
    handlePaymentForAddPaymentUser
      ? checkoutDispatch(updatePaymentMethodForTextToPayFlow(updatedPayments))
      : checkoutDispatch(updatePaymentCard(updatedPayments));
    Toast_Func({ status: true, message: 'Selected Payment is removed' });
    setPaymentInvalidMessage?.('');
    handlePaymentForAddPaymentUser
      ? checkoutDispatch(
          updateAmountOnSplitForTextToPayFlow({
            totalAmount: Number(fixedTotalAmount),
          }),
        )
      : checkoutDispatch(
          updateAmountOnSplit({ totalAmount: Number(fixedTotalAmount) }),
        );
  };

  const resetInvalid = () => {
    setIsInvalidAmount(false);
    setInvalidAmountMessage(null);
  };
  const hidePaymentSelection = () => {
    setAddPaymentCard(false);
    setAddSecondSplitMethod(false);
  };

  const hideGiftCardSelection = () => {
    setAddGiftCard(false);
    setAddSecondSplitMethod(false);
  };

  const removeCardFromOrder = async (card: string | number) => {
    const modifiesValues = {
      cart: {
        id: orderId,
      },
      gift_card_no: card,
    };
    try {
      const response = await paymentService.removeGiftCard(modifiesValues);
      Toast_Func({ status: true, message: 'Gift card is removed' });
      setPaymentInvalidMessage?.('');
      return true;
    } catch (error) {
      Toast_Func({ status: false, message: error.response.data.message });
      return false;
    } finally {
      setDisableRemove(false);
    }
  };

  const handleBackButton = () => {
    setAddPaymentCard(false);
    setAddGiftCard(false);
    setAddSecondSplitMethod(false);
  };
  const removeCard = async (cardNumber: string | number) => {
    setDisableRemove(true);
    resetInvalid();
    const updatedGiftCards = removeGiftCard(addedCards, cardNumber);
    if (await removeCardFromOrder(cardNumber)) {
      if (handlePaymentForAddPaymentUser) {
        checkoutDispatch(updateGiftCardForTextToPayFlow(updatedGiftCards));
        checkoutDispatch(
          updateAmountOnSplitForTextToPayFlow({
            totalAmount: Number(fixedTotalAmount),
          }),
        );
      } else {
        checkoutDispatch(updateGiftCard(updatedGiftCards));
        checkoutDispatch(
          updateAmountOnSplit({ totalAmount: Number(fixedTotalAmount) }),
        );
      }
    }
  };

  const showAddButtons = () => {
    return allSelectedCards.length < MAX_PAYMENT_LIMIT && !addSecondSplitMethod;
  };

  const verifySplitAmount = () => {
    setAddPaymentCard(false);
    setAddGiftCard(false);
    const czCard = addedAmounts.find((card) => card.type === GIFT_CARD);
    if (czCard?.amount > defaultGiftCard?.balance) {
      setIsInvalidAmount(true);
      setInvalidAmountMessage('CZ Dollars are less than the amount added.');
    } else {
      let amount = 0;
      if (addedAmounts.length > 0)
        for (const card of addedAmounts) {
          amount = amount + card.amount;
        }
      else
        allSelectedCards.map((card) => {
          amount = amount + (card.amount ? card.amount : 0);
        });
      if (addedAmounts.length > 0 || allSelectedCards.length > 0) {
        setIsInvalidAmount(Number(amount.toFixed(2)) != fixedTotalAmount);
        setInvalidAmountMessage("Split amount doesn't match the total.");
        if (Number(amount.toFixed(2)) == totalAmount) saveCardAmounts();
        else if (!isSplitPayment) handleSplitToggle();
      } else {
        handleSplitToggle();
      }
      if (allSelectedCards.length == 0)
        handlePaymentForAddPaymentUser
          ? checkoutDispatch(updateIsSplitPaymentForTextToPayFlow(false))
          : checkoutDispatch(updateIsSplitPayment(false));
    }
  };

  const saveCardAmounts = () => {
    for (const card of addedAmounts) {
      if (card.type == GIFT_CARD)
        handleGiftSplitAmount(card.amount, card.index);
      else handleCardSplitAmount(card.amount, card.index);
    }
    handlePaymentForAddPaymentUser
      ? checkoutDispatch(updateIsSplitPaymentForTextToPayFlow(isSplitPayment))
      : checkoutDispatch(updateIsSplitPayment(isSplitPayment));
    handleSplitToggle();
  };

  const handleSplitToggle = () => {
    if (!isSplitPayment) {
      {
        handlePaymentForAddPaymentUser
          ? checkoutDispatch(updatePaymentMethodForTextToPayFlow([]))
          : checkoutDispatch(updatePaymentCard([]));
        handlePaymentForAddPaymentUser
          ? checkoutDispatch(updateGiftCardForTextToPayFlow([]))
          : checkoutDispatch(updateGiftCard([]));
        handlePaymentForAddPaymentUser
          ? checkoutDispatch(
              updateIsSplitPaymentForTextToPayFlow(isSplitPayment),
            )
          : checkoutDispatch(updateIsSplitPayment(isSplitPayment));
        setEditCard(
          paymentRecords?.find((payment) => {
            return paymentMethods.length
              ? payment.id === paymentMethods[0]?.id
              : payment?.is_default;
          }),
        );
        setReplacePaymentCards(true);
        resetInvalid();
      }
    } else {
      handleCloseSplitPayment?.();
    }
  };
  const handleAmountAdded = (amountAdded: any) => {
    setPaymentInvalidMessage?.('');
    const cardIndex = addedAmounts.findIndex((card) => {
      return card.index == amountAdded.index && card.type == amountAdded.type;
    });
    const amountDuplicate = _clonedeep(addedAmounts);
    cardIndex > -1
      ? (amountDuplicate[cardIndex].amount = amountAdded.amount)
      : amountDuplicate.push(amountAdded);
    setAddedAmounts(amountDuplicate);
  };
  const handleCardSplitAmount = (amountAdded: number, index: number) => {
    handlePaymentForAddPaymentUser
      ? checkoutDispatch(
          updatePaymentCardAmountForTextToPayFlow({ amountAdded, index }),
        )
      : checkoutDispatch(updatePaymentCardAmount({ amountAdded, index }));
  };

  const handleGiftSplitAmount = (amountAdded: number, index: number) => {
    handlePaymentForAddPaymentUser
      ? checkoutDispatch(
          updateGiftCardAmountForTextToPayFlow({ amountAdded, index }),
        )
      : checkoutDispatch(updateGiftCardAmount({ amountAdded, index }));
  };

  useEffect(() => {
    if (!isSplitPayment) {
      verifySplitAmount();
    }
  }, [isSplitPayment]);

  useEffect(() => {
    const savedAmounts = [];
    addedCards.map((giftCard: IGiftCard, index: number) => {
      savedAmounts.push({
        amount: Number(giftCard.amount),
        index: index,
        type: GIFT_CARD,
      });
    });
    paymentMethods.map((paymentMethod: ICheckoutPayment, index: number) => {
      savedAmounts.push({
        amount: Number(paymentMethod.amount),
        index: index,
        type: PAYMENT_CARD,
      });
    });
    setAddedAmounts(savedAmounts);
  }, [paymentMethods, addedCards]);
  return (
    <div>
      {isSplitPayment && (
        <>
          {addPaymentCard || addGiftCard ? (
            <div>
              <button
                className={`ps-0 btn btn-custom f-s14 font-rale back-arrow-btn  align-items-center gap-2 d-flex mb-4`}
                onClick={() => handleBackButton()}
              >
                Back
              </button>
            </div>
          ) : null}
          <div className=" mx-auto full_width_mob">
            <h5 className="f-s22 font-Cls  text-start mb-2 text-capitalize ">
              Split Payment Method
            </h5>
            <hr className="custom_hr_sty mt-0" />
            {totalAmount >= 2 ? (
              <SplitPaymentToggle
                setSplitToggle={setIsSplitPayment}
                isSplitPayment={isSplitPayment}
                defaultCard={defaultGiftCard}
              />
            ) : null}

            <div className="d-flex justify-content-between align-items-center">
              <span className="font-Visby-cf f-w8 f-s16 h-19">Order Total</span>
              <span className="font-Visby-cf f-w8 f-s16 h-19">
                ${totalAmount}
              </span>
            </div>
            <span className={'clr-dark-red f-s14 d-flex'}>
              {paymentValidationMessage}
            </span>
            <hr className="custom_hr_sty mt-3" />
            <div className={styles.splitBox}>
              {paymentMethods.map(
                (paymentMethod: ICheckoutPayment, index: number) => {
                  return (
                    <SelectedPaymentCard
                      paymentCard={paymentMethod}
                      removePayment={removePayment}
                      index={index}
                      paymentMethods={paymentMethods}
                      handleCardSplitAmount={handleCardSplitAmount}
                      handleAmountAdded={handleAmountAdded}
                      resetInvalid={resetInvalid}
                    />
                  );
                },
              )}
            </div>
            {addedCards.map((giftCard: IGiftCard, index: number) => {
              return (
                <SelectedGiftCard
                  disableRemove={disableRemove}
                  giftCard={giftCard}
                  removeCard={removeCard}
                  handleGiftSplitAmount={handleGiftSplitAmount}
                  index={index}
                  handleAmountAdded={handleAmountAdded}
                  resetInvalid={resetInvalid}
                  addedCards={addedCards}
                />
              );
            })}
            {showAddButtons() && (
              <>
                <button
                  type="button"
                  className=" btn-large outline w-100  mt-4"
                  onClick={() => {
                    setAddPaymentCard(true);
                    setAddSecondSplitMethod(true);
                    resetInvalid();
                  }}
                >
                  Add CREDIT CARD
                </button>
                {/*<button*/}
                {/*  type="button"*/}
                {/*  className=" btn-large outline w-100  mt-4"*/}
                {/*  onClick={() => {*/}
                {/*    setAddGiftCard(true);*/}
                {/*    setAddSecondSplitMethod(true);*/}
                {/*    resetInvalid();*/}
                {/*  }}*/}
                {/*>*/}
                {/*  ADD gift card*/}
                {/*</button>*/}
              </>
            )}
            <hr className="custom_hr_sty mt-3" />
            {isInvalidAmount && (
              <p className="f-s14 h-16 f-w5 font-Visby-cf w-50 my-3 text-danger mx-auto">
                {invalidAmountMessage}
              </p>
            )}
            {!addSecondSplitMethod && (
              <button
                type="button"
                className="btn-large w-100  mt-5"
                onClick={() => verifySplitAmount()}
                disabled={isInvalidAmount}
              >
                Continue
              </button>
            )}
          </div>
        </>
      )}
      {addPaymentCard ? (
        <PaymentModal
          setOrderTippingAmount={setOrderTippingAmount}
          editPayment={editCard}
          handleCloseEditPayment={
            addSecondSplitMethod
              ? hidePaymentSelection
              : handleCloseSplitPayment
          }
          addSplitPayment={isSplitPayment}
          checkout={checkout}
          totalAmount={totalAmount}
        />
      ) : null}
      {addGiftCard ? (
        <GIftCardForm
          setOrderTippingAmount={setOrderTippingAmount}
          handleCloseGiftCard={
            addSecondSplitMethod
              ? hideGiftCardSelection
              : handleCloseSplitPayment
          }
          editGiftCard={editCard}
          addSplitPayment={isSplitPayment}
          checkout={checkout}
          totalAmount={totalAmount}
        />
      ) : null}
      {replacePaymentCards && (
        <PaymentModal
          setOrderTippingAmount={setOrderTippingAmount}
          editPayment={editCard}
          handleCloseEditPayment={handleCloseSplitPayment}
          addSplitPayment={isSplitPayment}
          checkout={checkout}
          totalAmount={totalAmount}
        />
      )}
      {replaceGiftCards && (
        <GIftCardForm
          setOrderTippingAmount={setOrderTippingAmount}
          handleCloseGiftCard={handleCloseSplitPayment}
          editGiftCard={editCard}
          addSplitPayment={isSplitPayment}
          checkout={checkout}
          totalAmount={totalAmount}
          handlePaymentForAddPaymentUser={handlePaymentForAddPaymentUser}
        />
      )}
    </div>
  );
};

export default SplitPaymentMethod;
