import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import {
  COMPLIMENTARY_MODIFIER,
  NO,
  ROUTE_OTHER_ITEM,
} from '../../../constants';
import useRedeemOffer from '../../../hooks/useRedeemOffer';
import {
  cartItem,
  quantityCounterOperations,
} from '../../../models/cart.model';
import { IOfferItem } from '../../../models/rewards.model';
import { setShowCart } from '../../../redux/slices/cartSlice';
import { useAppDispatch } from '../../../redux/store/store';

import ActionButtons from './ActionButtons';
import CartComboModifiers from './CartComboModifiers';
import styles from './CartItems.module.scss';
import ItemPrice from './ItemPrice';
import ItemRemoveModal from './itemRemoveModal';
import QuantityCounter from './QuantityCounter';
import { DownloadAppModal } from 'src/components/ItemOrderModal/DownloadAppModal';

interface ICartComboItemCard {
  item: cartItem;
  selectedOfferItem: IOfferItem;
  subTotal: number;
  handleQuantityChange: (
    item_id: string | number,
    iteratingIndex: number,
    itemQuantity: number,
  ) => void;
  handleItemRemove: (
    item_id: string | number,
    iteratingIndex: number,
    closeModal: () => void,
  ) => void;
  isActive: boolean;
  iteratingIndex: number;
  loading: boolean;
}
const CartComboItemCard: React.FC<ICartComboItemCard> = ({
  item,
  selectedOfferItem,
  subTotal,
  loading,
  iteratingIndex,
  handleQuantityChange,
  handleItemRemove,
}) => {
  const [divHeight, setDivHeight] = useState<number>(0);
  const itemHeight = useRef(null);
  const [itemQuantity, setItemQuantity] = useState<number>(item.quantity ?? 1);
  const [removeModal, setRemoveModal] = useState(false);
  const [showMoreNames, setShowMoreNames] = useState(false);
  const [showMoreIntructuons, setShowMoreIntructuons] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { search } = location;
  const query = new URLSearchParams(search);
  const editItemId = query.get('id');
  const dispatch = useAppDispatch();

  const [showDownloadApp, setShowDownloadApp] = useState<boolean>(false);
  const closeDownloadAppModal = () => {
    setShowDownloadApp(false);
  };

  const handleshowMoreNames = () => setShowMoreNames(!showMoreNames);
  const handleShowMoreIntructuons = () =>
    setShowMoreIntructuons(!showMoreIntructuons);
  const handleQuantityChangeWrapper = async (
    operation: quantityCounterOperations,
  ) => {
    let quantity = itemQuantity;
    if (operation === quantityCounterOperations.increase) {
      quantity = itemQuantity + 1;
    } else {
      quantity = itemQuantity > 1 ? itemQuantity - 1 : 1;
    }
    setItemQuantity(quantity);
    handleQuantityChange(item.id, iteratingIndex, quantity);
  };

  const { reVerifyOfferEligibility } = useRedeemOffer();
  useEffect(() => {
    reVerifyOfferEligibility(selectedOfferItem, item, subTotal);
  }, [subTotal]);
  const handleItemEdit = () => {
    const flags = item.is_package
      ? `is_package=true`
      : `is_box_lunch=${item.is_box_lunch ? true : false}`;
    const route = {
      pathname: `${ROUTE_OTHER_ITEM}/${item.item_id}`,
      search: `?category_id=${item?.category_id}&${flags}&id=${item.id}`,
      state: {
        showCustomization: true,
        isSingleItem: item.is_single_item,
        isPremiumPackage: item?.is_premium ?? false,
      },
    };
    history.push(route);
    dispatch(setShowCart(false));
  };

  useEffect(() => {
    if (item.quantity) setItemQuantity(item.quantity);
  }, [item.quantity]);

  const displayPrice = () => {
    const price = item.gross_price ?? item.display_price;
    return parseFloat((price * itemQuantity).toFixed(2));
  };

  useEffect(() => {
    if ((!divHeight || divHeight <= 0) && itemHeight?.current?.scrollHeight) {
      setDivHeight(itemHeight?.current?.scrollHeight);
    }
  }, [item?.special_instructions]);

  const showItemRemoveModal = () => {
    setRemoveModal(true);
  };
  const hideItemRemoveModal = () => {
    setRemoveModal(false);
  };
  const unavailableItems = item.modifiers

    .filter((element) => {
      if (element.is_item && !element.available && element.code !== NO)
        return element;
      else if (
        item?.unavailable_modifiers?.includes(element?.modifier_name) &&
        element?.modifier_type === COMPLIMENTARY_MODIFIER &&
        element.quantity > 0
      )
        return element;
    })
    .map((item) => item.modifier_name);
  let unavailableModifiers = [];
  item.modifiers.forEach((item: any) => {
    if (
      item?.modifier_type !== COMPLIMENTARY_MODIFIER &&
      item?.unavailable_modifiers?.length
    ) {
      unavailableModifiers = unavailableModifiers.concat(
        item.unavailable_modifiers,
      );
    }
  });

  const isComboEditAble = () => {
    return parseInt(editItemId) === item.id || unavailableItems.length > 0
      ? true
      : false;
  };
  const recipientNameArr = item?.recipient_name?.split(',');

  return (
    <>
      <li className="text-center mb-3">
        <div
          className={`${styles.list_item} text-center mb-0 w-100 ${
            (!item.available ||
              unavailableItems.length > 0 ||
              unavailableModifiers.length) &&
            styles.error_state
          }`}
        >
          <h1 className="d-flex justify-content-between font-Cls f-s16 ls-normal lh-normal m-0 text-start">
            <span>{item.name}</span>
            <ActionButtons
              onEdit={handleItemEdit}
              onRemove={showItemRemoveModal}
              isFree={item.free}
              isCombo={isComboEditAble()}
            />
            <ItemRemoveModal
              showModal={removeModal}
              handleClose={hideItemRemoveModal}
              removeItem={() =>
                handleItemRemove(item.id, iteratingIndex, hideItemRemoveModal)
              }
              loading={loading}
            />
          </h1>
          <CartComboModifiers
            modifiers={item.modifiers}
            isPackage={item?.is_package}
          />
          <div className={styles.nameSection}>
            <span
              className={`d-block f-s14 font-Visby-cf f-w6 mt-1 text-start text-capitalize ${
                showMoreNames && styles.nameText
              }`}
              ref={itemHeight}
            >
              {recipientNameArr?.map((item, index) => {
                return (
                  <span className={`f-w6 mb-0`}>
                    {' '}
                    {item}
                    {index < recipientNameArr?.length - 1 ? ',' : ''}{' '}
                  </span>
                );
              })}
            </span>
            <div className="d-flex justify-content-start">
              {divHeight > 21 && (
                <button onClick={handleshowMoreNames}>
                  {' '}
                  {showMoreNames ? 'show more' : 'show less'}
                </button>
              )}
            </div>
          </div>

          <div className={styles.nameSection}>
            <span
              className={`d-block f-s14 font-Visby-cf f-w6 mt-1 text-start text-capitalize ${
                showMoreIntructuons && styles.nameText
              }`}
              ref={itemHeight}
            >
              {item?.special_instructions}
            </span>
            <div className="d-flex justify-content-start">
              {divHeight > 21 && (
                <button onClick={handleShowMoreIntructuons}>
                  {' '}
                  {showMoreIntructuons ? 'show more' : 'show less'}
                </button>
              )}
            </div>
          </div>

          <span
            className={' d-flex justify-content-between align-items-end mt-3'}
          >
            <QuantityCounter
              quantity={itemQuantity}
              editQuantity={handleQuantityChangeWrapper}
              disabled={!item.available || unavailableItems.length > 0}
            />
            <ItemPrice price={displayPrice()} />
          </span>
        </div>
        {!item.available ? (
          <span
            className="text-center clr-dark-red f-s14 font-rale d-inline-block pt-2 cursor-pointer"
            onClick={showItemRemoveModal}
          >
            This item is no longer available. Please remove from your order.
          </span>
        ) : unavailableItems.length > 0 ? (
          <span
            className="text-center clr-dark-red f-s14 font-rale d-inline-block pt-2 cursor-pointer"
            onClick={showItemRemoveModal}
          >
            Unfortunately, we are currently out of {unavailableItems.join(', ')}{' '}
            today.
          </span>
        ) : (
          unavailableModifiers.length > 0 && (
            <span
              className="text-center clr-dark-red f-s14 font-rale d-inline-block pt-2 cursor-pointer"
              onClick={showItemRemoveModal}
            >
              Unfortunately, we are currently out of{' '}
              {unavailableModifiers?.join(', ')} today.
            </span>
          )
        )}
      </li>
      <DownloadAppModal
        showModal={showDownloadApp}
        closeModal={closeDownloadAppModal}
        item={item}
        edit={true}
      />
    </>
  );
};

export default CartComboItemCard;
