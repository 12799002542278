import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IParams } from 'src/models/card';
import { itemBuckets } from 'src/priceCalculation/buckets';
import { ADDITIONAL_ADDED } from 'src/priceCalculation/constants';
import { modifiersAgainstAnItem } from 'src/priceCalculation/helper';

import * as Constants from '../../constants';
import {
  ICoreModifiers,
  IModifierGroups,
  IModifiersObjects,
  IRequiredModifier,
  ItemDetails,
} from '../../models/item.model';
import {
  removeTemporarilyAddedPriceForItem,
  updateSpecificItemSelectedModifiers,
} from '../../redux/slices/itemCustomizationSlice';

import CoreModifiers from './ingredients/CoreModifiers';
import ModifierGroups from './ingredients/ModifierGroups';
import RequiredModifier from './ingredients/RequiredModifier';
import ItemsAsModifiers from './ItemsAsModifiers';
import { IOrder } from 'src/models/order.model';
import { IItemAsModifiers } from 'src/models/order';

type propsType = {
  coreModifiers: ICoreModifiers[];
  handleIngredientSelection: (data: any) => void;
  modifierGroups: IModifierGroups;
  requiredModifiers: IRequiredModifier;
  itemName: string;
  showCustomization: boolean;
  itemModifierItemModalWidth?: string;
  cardColSize?: string;
  itemsAsModifiers?: IItemAsModifiers[];
  isPackege?: boolean;
  itemAsModifierState?: {
    itemsAsModifier: IItemAsModifiers[];
    setItemsAsModifier: Dispatch<SetStateAction<any[]>>;
  };
  isCreateYourOwnSalad?: boolean;
  CYOSScrollToMissingRequiredArea?: boolean;
  itemsAsModifiersToHandleScroll?: {
    refToScrollToMissingRequiredItemAsModifiers: any[];
    isItemAsModifierSelected: {
      status: boolean;
      highLightArea: number;
    };
    setIsItemAsModifierSelected: React.Dispatch<
      React.SetStateAction<{ status: boolean; highLightArea: any }>
    >;
  };
  isCYOSColSize?: string;
  selectedItem: 1 | 2 | 3 | 4;
  openItemInfoModal?: (item: ItemDetails) => void;
};

const AddIngredients: React.FC<propsType> = (props) => {
  const {
    coreModifiers,
    handleIngredientSelection,
    modifierGroups,
    requiredModifiers,
    itemName,
    showCustomization,
    itemModifierItemModalWidth,
    cardColSize,
    itemsAsModifiers,
    isPackege,
    itemAsModifierState,
    isCreateYourOwnSalad,
    CYOSScrollToMissingRequiredArea,
    itemsAsModifiersToHandleScroll,
    isCYOSColSize,
    selectedItem,
    openItemInfoModal,
  } = props;

  const dispatchItemCustomization = useDispatch();

  const coreModifierBucket = (function () {
    return itemBuckets.getSingleBucket({
      name: Constants.CORE_MODIFIERS,
      fromItem: selectedItem,
    });
  })();
  const isItCoreRelated = (groupId: number) => {
    if (coreModifierBucket && coreModifierBucket['memoryChip']) {
      const isExist = coreModifierBucket['memoryChip'][groupId];
      if (isExist === undefined) {
        return false;
      } else {
        return true;
      }
    }
  };

  const selectedAddedAddOns: any[] = (function () {
    const fromItem: any = selectedItem - 1;
    return itemBuckets.getSingleBucketKeyValue({
      name: Constants.ADD_ONS,
      fromItem,
      modifierType: ADDITIONAL_ADDED,
      key: 'modifiers',
    });
  })();

  const isLimitExceed = (
    groupId: number,
    extendable_limit: number,
    groupMin,
  ) => {
    if (extendable_limit === 1 && groupMin === 1) {
      return false;
    } else {
      if (!isItCoreRelated(groupId)) {
        return selectedAddedAddOns?.length >= extendable_limit ? true : false;
      } else {
        if (coreModifierBucket['memoryChip'][groupId]?.changes) {
          return coreModifierBucket['memoryChip'][groupId]?.changes?.quantity >=
            extendable_limit
            ? true
            : false;
        } else {
          return coreModifierBucket['memoryChip'][groupId]?.quantity >=
            extendable_limit
            ? true
            : false;
        }
      }
    }
  };

  const selectedCore: any[] = (function () {
    return itemBuckets.specificItemBucketSelectedModifiers(
      Constants.CORE_MODIFIERS,
      selectedItem,
    );
  })();

  const handleCoreModifierClick = (
    data: IParams,
    modifier: ICoreModifiers,
    type: string,
  ) => {
    handleIngredientSelection({
      ...data,
      ...{
        modifier_group_id: modifier?.modifier_group_id,
        modifier_type: type,
      },
    });
  };

  const selectedAddOns = (function () {
    return itemBuckets.specificItemBucketSelectedModifiers(
      Constants.ADD_ONS,
      selectedItem,
    );
  })();

  const handleClick = (
    data: IParams,
    modifierGroup: IModifierGroups,
    type: string,
  ) => {
    handleIngredientSelection({
      ...data,
      ...{
        modifier_group_id: modifierGroup?.id,
        modifier_group_max: modifierGroup?.max,
        modifier_group_base: modifierGroup?.base,
        modifier_type: type,
      },
    });
  };

  const noDressingHandler = (modifier: any, itemNo) => {
    dispatchItemCustomization(
      removeTemporarilyAddedPriceForItem({
        price: itemBuckets.resetChangesOnNoDreesing(itemNo, modifier),
        item: itemNo,
      }),
    );
    dispatchItemCustomization(
      updateSpecificItemSelectedModifiers({
        itemModifiers: modifiersAgainstAnItem({
          itemId: selectedItem,
        }),
        itemId: selectedItem.toString(),
      }),
    );
  };

  const showItemsAsModifiers = () => {
    return itemsAsModifiers.length > 0 ? (
      <ItemsAsModifiers
        itemAsModifiers={itemsAsModifiers}
        cardColSize={cardColSize}
        isPackege={isPackege}
        itemAsModifierState={itemAsModifierState}
        itemsAsModifiersToHandleScroll={itemsAsModifiersToHandleScroll}
        openItemInfoModal={openItemInfoModal}
      />
    ) : null;
  };

  return (
    <>
      {isCreateYourOwnSalad ? (
        <div className={`${isPackege && 'ps-md-2 packagesModifiers'}`}>
          <RequiredModifier
            sectionSelected={selectedItem.toString()}
            itemIngredients={requiredModifiers}
            handleIngredientSelection={handleIngredientSelection}
            noDressingHandler={noDressingHandler}
            showMaxSelection={
              itemName.toLowerCase() ===
              Constants.CREATE_YOUR_OWN_SALAD_TRAY.toLowerCase()
                ? false
                : true
            }
            customizationModule={false}
            showAdditionalPrice={true}
            isCYOSColSize={isCYOSColSize}
            itemModifierItemModalWidth={itemModifierItemModalWidth}
            isCreateYourOwnSalad={isCreateYourOwnSalad}
            CYOSScrollToMissingRequiredArea={CYOSScrollToMissingRequiredArea}
          />
        </div>
      ) : (
        <div className={`${isPackege && 'ps-md-2 packagesModifiers'}`}>
          {showItemsAsModifiers()}
          <CoreModifiers
            coreModifiers={coreModifiers}
            selectedCore={selectedCore}
            handleCoreModifierClick={handleCoreModifierClick}
            isLimitExceed={isLimitExceed}
            cardColSize={cardColSize}
            isPackege={isPackege}
          />
          <ModifierGroups
            modifierGroups={modifierGroups}
            selectedAddOns={selectedAddOns}
            selectedCore={selectedCore}
            handleClick={handleClick}
            isLimitExceed={isLimitExceed}
            showCustomization={showCustomization}
            itemModifierItemModalWidth={itemModifierItemModalWidth}
            cardColSize={cardColSize}
            isPackege={isPackege}
          />
        </div>
      )}
    </>
  );
};

export default AddIngredients;
