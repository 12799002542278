import React from 'react';
import { Modal } from 'react-bootstrap';
// import Scrollbars from 'react-custom-scrollbars';
import { Link } from 'react-router-dom';
import { SINGLE_ITEM_SELECTED_SECTION } from 'src/constants';

import AddIngredients from './AddIngredients';

interface ICustomizationModal {
  modalShow: boolean;
  onModalCancel: () => void;
  item: any;
  handleIngredientSelection: (data: any, id?: number) => void;
  styleClasses: { readonly [key: string]: string };
  applyClickHandler: () => void;
  isItEdit?: boolean;
  showCustomization?: boolean;
}

const CustomizationModal: React.FC<any> = (props) => {
  const {
    modalShow,
    onModalCancel,
    item,
    handleIngredientSelection,
    styleClasses,
    applyClickHandler,
    isItEdit,
    showCustomization,
    order,
    cardColSize,
    itemAsModifierState,
    isCreateYourOwnSalad,
  } = props;

  return (
    <Modal
      scrollable={true}
      show={modalShow}
      onHide={onModalCancel}
      centered
      className={`theme-modal-style app_container_wrap no-padding border-radius-15 border-radius-sm-12 upperside-radius-sm-only item-customization--modal ${
        isCreateYourOwnSalad && 'create-your-own-salad-modal'
      }`}
      size="lg"
    >
      <Modal.Header className="pt-0" id="close_button2">
        <button
          type="button"
          className="btn modal-close-button pe-0 sm-blue-icon"
          onClick={onModalCancel}
        >
          Close
        </button>
      </Modal.Header>
      <Modal.Body className={` px-3 px-md-0 text-center`}>
        <h2 className="font-Cls f-s22 ls-normal lh-normal text-center">
          {item?.name}
        </h2>
        <div className="CYOS_inner_scrollbar">
          <AddIngredients
            modifierGroups={item?.items_modifier_groups}
            coreModifiers={item?.core_modifiers}
            requiredModifiers={item?.items_required_modifier_groups}
            itemsAsModifiers={item?.sub_items_required_modifier_groups}
            handleIngredientSelection={(data: any) =>
              handleIngredientSelection(data, SINGLE_ITEM_SELECTED_SECTION)
            }
            selectedItem={SINGLE_ITEM_SELECTED_SECTION}
            itemName={item?.name}
            showCustomization={showCustomization}
            isCYOSColSize={'col-lg-3'}
            cardColSize={cardColSize}
            itemAsModifierState={itemAsModifierState}
            isCreateYourOwnSalad={isCreateYourOwnSalad}
            CYOSScrollToMissingRequiredArea={false}
          />
        </div>
        <button
          type="button"
          className={`${styleClasses.order_item_order_btn}  btn-large ms-0 mt-3`}
          onClick={applyClickHandler}
        >
          Apply
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default CustomizationModal;
