import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import _clonedeep from 'lodash.clonedeep';
import Button from 'src/components/Button/Button';
import OrderNameForm from 'src/components/OrderNameForm/OrderNameForm';
import {
  ADD_TO_ORDER,
  APPLY,
  COMPLEMENTARY_SELECTED_SECTION_FOR_SINGLE_ITEM,
  COMPLEMENTARY_SELECTED_SECTION_FOR_TRY_TO_COMBO_ITEM,
  CUSTOMIZE,
  FULL_SIZE,
  HALF_SIZE,
  REQUIRED_MODIFIERS,
  SELECTED,
  SINGLE_ITEM_SELECTED_SECTION,
} from 'src/constants';
import {
  availableForSelectedOrderType,
  unavailableModifiersList,
  verifyStatus,
} from 'src/helper/customizedItem/customizedItem.helper';
import { getCustomizedItemPrice } from 'src/helper/getCombinePrice/getCombinePrice.helper';
import {
  bucketKeyForNonRequiredTray,
  creatingModifiersForItems,
  itemSize,
  scrollPercentage,
  scrollToElementByRef,
} from 'src/helper/helperMethods';
import useCheckMobileScreen from 'src/hooks/useCheckMobileScreen';
import {
  Codes,
  ICoreModifiers,
  IItemsRequiredModifierGroups,
  IRefStatesForScrolling,
  IRequiredModifiersGroupsModifiers,
  ISubCategories,
  ItemDetailForm,
  ItemDetails,
  ItemModal,
} from 'src/models/item.model';
import { Menu } from 'src/models/Menu.model';
import { IAddToCartPayload } from 'src/models/order';
import { IItemDetail, ISelectedItem } from 'src/models/try2Combo';
import { itemBuckets } from 'src/priceCalculation/buckets';
import { toFixedNumber } from 'src/priceCalculation/helper';
import { ISingleBucket } from 'src/priceCalculation/priceCalculation';
import useCategories from 'src/react-query-hooks/useCategories';
import {
  removeSpecificItem,
  sizeSelection,
  try2ComboItemSelection,
} from 'src/redux/slices/itemCustomizationSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/store/store';
import { awsAssetsFetcher } from 'src/utils/awsAssetsFetcher';

import * as CONSTANT from '../../constants';
import styles from '../../Features/Menu/menu.module.scss';
import ComplementaryItems from '../Modifiers/ComplementaryItems';

import ItemDescription from './ItemDescription';
import ItemDetailImage from './ItemDetailImage';
import ItemSize from './ItemSize';
import RequiredListing from './RequiredListing';
const SIZE_NOT_SELECTED = 'SIZE NOT SELECTED';
const REQUIREDMODIFIER_NOT_SELECTED = 'REQUIREDMODIFIER NOT SELECTED';

import { isMobile } from 'react-device-detect';
import AddedModal from 'src/Features/BoxLunches/AddedModal/AddedModal';
import ServingTextModal from 'src/Features/BoxLunches/servingTextModal/servingTextModal';
import useIsInViewport from 'src/hooks/useVisibility';
import { cartItem } from 'src/models/cart.model';

import ItemsBox from './SelectedItems/ItemsBox';
import ItemCard from './ItemCard';

type propTypes = {
  item: ItemDetails;
  state?: unknown;
  togglePersonalize?: () => void;
  showItemCustomiseModal?: () => void;
  showModifierModal?: () => void;
  handleScrollToCustomisation?: () => void;
  handleIngredientSelection: (data, id) => void;
  handleComplementaryIngredientSelection?: (data, id) => void;
  addToOrder?: (structuredOrder: any) => Promise<boolean>;
  isCoreForRequiredTraysAdded?: any;
  setManualSelectedItem?: (state) => void;
  manualSelectedItem?: ItemDetails;
  manualSelectedItemId?: 2 | 3;
  boxLunchCombo?: ItemModal[];
  findSelectedItemAvailability?: any;
  openItemInfoModal?: (item: ItemDetails) => void;
  isBoxLunch?: boolean;
  activeKey?: string;
  itemsAsModifier?: any[];
  isCustomizeSectionExposed?: boolean;
  Category?: (selected: ISelectedItem, id: string) => any;
  Item?: (selected: ISelectedItem, id: string) => any;
  menus?: Menu[];
  loading?: boolean;
  formState: ItemDetailForm;
  setFormState: React.Dispatch<React.SetStateAction<ItemDetailForm>>;
  resetBoxLunch?: (reset?: boolean) => void;
  customizedItem?: cartItem;
  showAddtoOrderModal?: boolean;
  setshowAddtoOrderModal?: React.Dispatch<React.SetStateAction<boolean>>;
  addToOrderButtonShown?: (state: boolean) => void;
  isCreateYourOwnSalad?: boolean;
  showCustomization?: boolean;
  dbId?: string;
  setChoseForMe?: any;
  CYOSScrollToMissingRequiredArea?: any;
  showItemCustomisationModal?: boolean;
  itemsAsModifiersToHandleScroll?: {
    refToScrollToMissingRequiredItemAsModifiers: any;
    isItemAsModifierSelected: {
      status: boolean;
      highLightArea: number;
    };
    setIsItemAsModifierSelected: React.Dispatch<
      React.SetStateAction<{ status: boolean; highLightArea: any }>
    >;
  };
  requiredModifiersAtItemDetailSectionToHandleScroll: IRefStatesForScrolling;
  subItemRequiredModifiersAtItemDetailSectionToHandleScroll?: IRefStatesForScrolling;
  requiredModifiersForDynamicSelectedItemAtItemDetailSectionToHandleScroll?: IRefStatesForScrolling;
  requiredItemForDynamicSelectedItemAtItemDetailSectionToHandleScroll?: IRefStatesForScrolling;
  onRemoveClickHandler?: (id: string) => void;
  onEditClickHandler?: any;
  setEditFromDetails?: any;
  stateJustForOtherMenuItems?: {
    startScrollingForMobile: {
      status: boolean;
      orderButtonStatus: any;
      updateStateFor:
        | typeof CONSTANT.CREATE_YOUR_OWN_SALAD_TRAY
        | typeof CONSTANT.ITEM_AS_MODIFIERS;
    };
    setStartScrollingForMobile: Dispatch<
      SetStateAction<{
        status: boolean;
        orderButtonStatus: any;
        updateStateFor:
          | typeof CONSTANT.CREATE_YOUR_OWN_SALAD_TRAY
          | typeof CONSTANT.ITEM_AS_MODIFIERS;
      }>
    >;
    IsAllItemInModifierSelected: ({ item, isCustomizeSectionExposed }) => {
      status: boolean;
      highLightSection: number;
    };
  };
  editCartItem?: any;
  isPremiumPackage?: boolean;
};
interface RouteParams {
  selected_category?: string;
}

const ItemDetail: React.FC<propTypes> = (props) => {
  const {
    item,
    state,
    togglePersonalize,
    showItemCustomiseModal,
    handleIngredientSelection,
    handleComplementaryIngredientSelection,
    isBoxLunch,
    addToOrder,
    activeKey,
    showModifierModal,
    setManualSelectedItem,
    manualSelectedItemId,
    manualSelectedItem,
    boxLunchCombo,
    openItemInfoModal,
    itemsAsModifier,
    isCustomizeSectionExposed,
    findSelectedItemAvailability,
    Item,
    Category,
    loading,
    setFormState,
    formState,
    resetBoxLunch,
    customizedItem,
    setshowAddtoOrderModal,
    showAddtoOrderModal,
    addToOrderButtonShown,
    isCreateYourOwnSalad,
    showCustomization,
    setChoseForMe,
    CYOSScrollToMissingRequiredArea,
    dbId,
    itemsAsModifiersToHandleScroll,
    handleScrollToCustomisation,
    onRemoveClickHandler,
    onEditClickHandler,
    setEditFromDetails,
    stateJustForOtherMenuItems,
    editCartItem,
    requiredModifiersAtItemDetailSectionToHandleScroll,
    subItemRequiredModifiersAtItemDetailSectionToHandleScroll,
    requiredModifiersForDynamicSelectedItemAtItemDetailSectionToHandleScroll,
    requiredItemForDynamicSelectedItemAtItemDetailSectionToHandleScroll,
    isPremiumPackage,
    isCoreForRequiredTraysAdded,
  } = props;

  const orderNameFromRef = useRef<HTMLDivElement>(null);

  const order = useSelector((state: any) => state.itemCustomization);
  const orderType = useSelector((state: any) => state.cart.orderType);

  const orderedIngredients = order?.modifiers[0]?.modifiers;

  const {
    traysItemRequiredModifierGroup,
    traysRequiredItem,
  }: {
    traysRequiredItem: IItemDetail[];
    traysItemRequiredModifierGroup: IItemsRequiredModifierGroups[];
  } = useGetRequiredModifierItems(item?.package_categories);

  const [
    scrollToNextMissingSectionAndIgnoreSaladTrayItems,
    setScrollToNextMissingSectionAndIgnoreSaladTrayItems,
  ] = useState(false);

  const { onCustomizeClick } = useShowCustomize({
    item,
    order,
    showCustomization,
    scrolling: {
      requiredModifiersAtItemDetailSectionToHandleScroll,
      subItemRequiredModifiersAtItemDetailSectionToHandleScroll,
      requiredModifiersForDynamicSelectedItemAtItemDetailSectionToHandleScroll,
      requiredItemForDynamicSelectedItemAtItemDetailSectionToHandleScroll,
      handleScrollToCustomisation,
      updateStateForNotSelectedSection,
    },
    currentItemRequiredModifierGroups: traysItemRequiredModifierGroup,
    bucketKey,
    manualSelectedItem,
    manualSelectedItemId,
    scrollToNextMissingSectionAndIgnoreSaladTrayItems,
    setScrollToNextMissingSectionAndIgnoreSaladTrayItems,
    orderNameFromRef,
  });

  const { user } = useAppSelector((state) => state.user);
  const locationId = useAppSelector((state) => state.location.selectedStore.id);

  const [servingTextModal, setServingTextModal] = useState<boolean>(false);
  const {
    data: menus = [],
    isFetching,
    refetch: refetchMenu,
  } = useCategories(locationId, user);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const isItMobile = useCheckMobileScreen();

  const { generateOrderStructure } = useAddToOrderPayloadStructure(
    order,
    item,
    formState,
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showCustomizedOptions, setShowCustomizedOptions] =
    useState<boolean>(false);

  const [isRequiredModifierSelected, setIsRequiredModifierSelected] =
    useState(false);
  const [isSize, setIsSize] = useState(false);

  const autoScrollDivRef = useRef<HTMLDivElement>(null);
  const unavailableModifiersListData = unavailableModifiersList(item);

  const isItemAvailable = () => {
    return (
      item?.is_active && verifyStatus(item, 'item_location')
      // availableForOrderType()
    );
  };

  const handleSizeForCustomization = (size: string): void => {
    const obj = {
      size: size,
      ...(size === FULL_SIZE.id && {
        brink_id: item.full_brink_item_id,
      }),
      ...(size === HALF_SIZE.id && {
        brink_id: item.half_brink_item_id,
      }),
    };
    dispatch(sizeSelection(obj));
  };
  const addSelectedModifiers = (selectedModifier, commulativeItems): void => {
    selectedModifier?.modifiers.forEach((element) => {
      const index = commulativeItems.findIndex(
        (e) => e.groupId === element?.modifier_group_id,
      );
      if (index !== -1) {
        commulativeItems[index].quantity =
          commulativeItems[index].quantity + element?.quantity;
        commulativeItems[index].noDressing = false;
      } else {
        const trackObj = {
          groupId: element?.modifier_group_id,
          quantity: element?.quantity,
          noDressing: false,
        };
        commulativeItems.push(trackObj);
      }
    });
  };
  const addNoDressings = (selectedModifier, commulativeItems): void => {
    selectedModifier?.noDressings.forEach((element) => {
      const index = commulativeItems.findIndex(
        (e) => e.groupId === element?.id,
      );
      if (index === -1) {
        const trackObj = {
          groupId: element?.id,
          quantity: 0,
          noDressing: element?.noDressing,
        };
        commulativeItems.push(trackObj);
      } else {
        commulativeItems[index].noDressing = element?.noDressing;
      }
    });
  };
  const getOrderButtonState = (
    type,
  ): { status: boolean; missingGroupIndex?: number } => {
    if (!availableForSelectedOrderType(item, orderType))
      return { status: true };
    const selectedModifier = itemBuckets.getSingleBucket({
      name: REQUIRED_MODIFIERS,
      fromItem: SINGLE_ITEM_SELECTED_SECTION,
    });
    const commulativeItems = [];
    let missingGroupIndex = null;

    if ((type && order?.size) || (type && order?.size)) {
      if (!item?.items_required_modifier_groups.length) {
        return { status: false };
      }
      addSelectedModifiers(selectedModifier, commulativeItems);
      if (selectedModifier?.noDressings) {
        addNoDressings(selectedModifier, commulativeItems);
      }
      let isMinExeceeded = null;

      for (let i = 0; i < item?.items_required_modifier_groups.length; i++) {
        const itemIndex = commulativeItems.findIndex(
          (e) => e.groupId === item?.items_required_modifier_groups[i].id,
        );
        missingGroupIndex = i;
        const minReached =
          commulativeItems[itemIndex]?.noDressing === false
            ? commulativeItems[itemIndex]?.quantity > 0 &&
              commulativeItems[itemIndex]?.quantity >=
                item?.items_required_modifier_groups[i].min
            : true;
        if (itemIndex === -1 || !minReached) {
          isMinExeceeded = false;
          break;
        } else {
          isMinExeceeded = true;
        }
      }
      if (type === APPLY && isMinExeceeded) {
        return { status: false };
      } else if (type === ADD_TO_ORDER && isMinExeceeded) {
        return { status: false };
      } else if (type === CUSTOMIZE) {
        return { status: false };
      } else {
        return {
          status: true,
          missingGroupIndex,
        };
      }
    } else {
      return {
        status: true,
        missingGroupIndex,
      };
    }
  };

  function updateStateForNotSelectedSection(
    type: typeof SIZE_NOT_SELECTED | typeof REQUIREDMODIFIER_NOT_SELECTED,
  ): void {
    switch (type) {
      case SIZE_NOT_SELECTED:
        {
          // window.scrollTo(0, 400);
          // autoScrollDivRef.current.scrollIntoView({ behavior: 'smooth' });
          scrollPercentage(20);
          if (window.innerWidth > 992) {
            scrollPercentage(10);
          }
          setIsSize(true);
          setTimeout(() => {
            setIsSize(false);
          }, 2000);
        }
        break;
      case REQUIREDMODIFIER_NOT_SELECTED:
        {
          scrollToElementByRef(autoScrollDivRef, isItMobile ? -155 : -250);

          setIsRequiredModifierSelected(true);
          setTimeout(() => {
            setIsRequiredModifierSelected(false);
          }, 2000);
        }
        break;
      default: {
        /* empty */
      }
    }
  }

  const customizeButtonHandler = (): void => {
    if (!isCreateYourOwnSalad) {
      const isNotDisabled = onCustomizeClick(CUSTOMIZE);
      if (!isNotDisabled && scrollToNextMissingSectionAndIgnoreSaladTrayItems) {
        orderNameFromRef.current.scrollIntoView({
          behavior: 'smooth',
          block: `${isMobile ? 'center' : 'center'}`,
        });
      }
      if (
        !isNotDisabled &&
        !scrollToNextMissingSectionAndIgnoreSaladTrayItems
      ) {
        showModifierModal();
      }
    } else {
      showModifierModal();
    }
    if (
      !isCreateYourOwnSalad &&
      scrollToNextMissingSectionAndIgnoreSaladTrayItems
    ) {
      setScrollToNextMissingSectionAndIgnoreSaladTrayItems(false);
    }
  };
  const handleFromFields = (formState): void => {
    setFormState({ ...formState });
  };

  const handleSelection = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    size,
    isHalf?: boolean,
  ): void => {
    if (isHalf && item?.is_box_lunch) {
      // console.log('inhere');
      togglePersonalize();
      handleSizeForCustomization(size);
      return;
    }
    if (item?.is_box_lunch) {
      setChoseForMe(true);
      resetBoxLunch();
      setServingTextModal(true);
    }
    handleSizeForCustomization(size);
  };
  const handleSelect = (currentItem: ItemDetails): void => {
    const id = isPremiumPackage
      ? CONSTANT.PREMIUM_PACKAGE_SELECTED_SECTION
      : CONSTANT.PACKAGE_SELECTED_SECTION;
    dispatch(removeSpecificItem({ id: id.toString() }));
    if (currentItem?.id === manualSelectedItem?.id) {
      isCoreForRequiredTraysAdded.current = false;
      setManualSelectedItem(null);
    } else {
      isCoreForRequiredTraysAdded.current = false;
      dispatch(removeSpecificItem({ id }));
      itemBuckets.resetSpecificComboBucket(id);
      setManualSelectedItem(currentItem);
      dispatch(
        try2ComboItemSelection({
          sectionSelected: item.is_premium
            ? CONSTANT.PREMIUM_PACKAGE_SELECTED_SECTION.toString()
            : CONSTANT.PACKAGE_SELECTED_SECTION.toString(),
          item: currentItem,
        }),
      );
    }
  };

  useEffect(() => {
    if (scrollToNextMissingSectionAndIgnoreSaladTrayItems) {
      customizeButtonHandler();
    }
  }, [scrollToNextMissingSectionAndIgnoreSaladTrayItems]);

  useEffect(() => {
    if (
      showItemCustomiseModal &&
      stateJustForOtherMenuItems?.startScrollingForMobile.status
    ) {
      const { startScrollingForMobile, setStartScrollingForMobile } =
        stateJustForOtherMenuItems;
      if (
        startScrollingForMobile.updateStateFor ===
        CONSTANT.CREATE_YOUR_OWN_SALAD_TRAY
      ) {
        startScrollingToMissingCYOSRequiredModifiers(
          startScrollingForMobile.orderButtonStatus,
        );
        setStartScrollingForMobile({
          status: false,
          orderButtonStatus: null,
          updateStateFor: null,
        });
      } else if (
        startScrollingForMobile.updateStateFor === CONSTANT.ITEM_AS_MODIFIERS
      ) {
        startScrollingToMissingItemAsModifiers(
          startScrollingForMobile.orderButtonStatus,
        );
        setStartScrollingForMobile({
          status: false,
          orderButtonStatus: null,
          updateStateFor: null,
        });
      }
    }
  }, [
    stateJustForOtherMenuItems?.startScrollingForMobile,
    showItemCustomiseModal,
  ]);

  useEffect(() => {
    unavailableModifiersListData &&
      !showCustomizedOptions &&
      setShowCustomizedOptions(true);
  }, [unavailableModifiersListData]);
  const unrepeatedIngredients: (
    | ICoreModifiers
    | IRequiredModifiersGroupsModifiers
  )[] = item
    ? [
        ...item.core_modifiers,
        ...item.items_required_modifier_groups
          .map((group) => group.required_modifiers_groups_modifiers)
          .flat(),
      ]
    : [];
  useEffect(() => {
    !item?.half_brink_item_id && item && handleSizeForCustomization('FULL');
  }, [item]);

  const startScrollingToMissingCYOSRequiredModifiers = (orderButtonStatus) => {
    const {
      refToScrollToMissingRequiredArea,
      isCYOSRequiredModifierSelected,
      setIsCYOSRequiredModifierSelected,
    } = CYOSScrollToMissingRequiredArea;
    setIsCYOSRequiredModifierSelected({
      status: orderButtonStatus.status,
      highLightArea: orderButtonStatus.missingGroupIndex,
    });
    setTimeout(() => {
      setIsCYOSRequiredModifierSelected({
        status: false,
        highLightArea: null,
      });
    }, 2000);
    for (let i = 0; i < refToScrollToMissingRequiredArea.length; i++) {
      if (i === orderButtonStatus.missingGroupIndex) {
        refToScrollToMissingRequiredArea[i].current?.scrollIntoView({
          behavior: 'smooth',
          block: `${isMobile ? 'start' : 'center'}`,
        });
        break;
      }
    }
  };

  const startScrollingToMissingItemAsModifiers = (orderButtonStatus) => {
    const {
      refToScrollToMissingRequiredItemAsModifiers,
      isItemAsModifierSelected,
      setIsItemAsModifierSelected,
    } = itemsAsModifiersToHandleScroll;
    setIsItemAsModifierSelected({
      status: orderButtonStatus.status,
      highLightArea: orderButtonStatus.missingGroupIndex,
    });
    setTimeout(() => {
      setIsItemAsModifierSelected({
        status: false,
        highLightArea: null,
      });
    }, 2000);
    for (
      let i = 0;
      i < refToScrollToMissingRequiredItemAsModifiers.length;
      i++
    ) {
      if (
        refToScrollToMissingRequiredItemAsModifiers[
          orderButtonStatus.missingGroupIndex
        ]
      ) {
        refToScrollToMissingRequiredItemAsModifiers[
          orderButtonStatus.missingGroupIndex
        ].current.scrollIntoView({
          behavior: 'smooth',
          block: `${isMobile ? 'start' : 'center'}`,
        });
        break;
      }
    }
  };

  const addToOrderHandler = async () => {
    if (!isCreateYourOwnSalad) {
      const isNotDisabled = onCustomizeClick(ADD_TO_ORDER);
      if (isNotDisabled) return;
    }

    let currentItems: any = [item];
    if (item.is_package) {
      currentItems = item.package_categories
        .filter((tray: ISubCategories) => tray.items.length === 1)
        .map((activeItem) => activeItem.items[0]);
    }
    for (let index = 0; index < currentItems.length; index++) {
      if (currentItems[index]?.sub_items_required_modifier_groups?.length > 0) {
        const { IsAllItemInModifierSelected, setStartScrollingForMobile } =
          stateJustForOtherMenuItems;
        const { status: isAllItemAsModifierSelected, highLightSection } =
          IsAllItemInModifierSelected({
            item: currentItems[index],
            isCustomizeSectionExposed,
          });

        if (!isAllItemAsModifierSelected) {
          showModifierModal();
          if (isMobile) {
            setStartScrollingForMobile({
              status: true,
              orderButtonStatus: {
                status: !isAllItemAsModifierSelected,
                missingGroupIndex: highLightSection,
              },
              updateStateFor: CONSTANT.ITEM_AS_MODIFIERS,
            });
          } else {
            startScrollingToMissingItemAsModifiers({
              status: !isAllItemAsModifierSelected,
              missingGroupIndex: highLightSection,
            });
          }
          return;
        }
      }
    }

    const orderButtonStatus = getOrderButtonState(CONSTANT.ADD_TO_ORDER);
    if (isCreateYourOwnSalad && orderButtonStatus.status) {
      if (isMobile) {
        showModifierModal();
        stateJustForOtherMenuItems.setStartScrollingForMobile({
          status: true,
          orderButtonStatus,
          updateStateFor: CONSTANT.CREATE_YOUR_OWN_SALAD_TRAY,
        });
      } else {
        startScrollingToMissingCYOSRequiredModifiers(orderButtonStatus);
      }
      return;
    }

    const genCall =
      item.is_box_lunch || item?.is_package
        ? {}
        : generateOrderStructure(order);
    const respose = await addToOrder(genCall);
    if (respose) {
      !item?.half_brink_item_id && item && handleSizeForCustomization('FULL');
    }
  };
  const isSizeCardHide = () => {
    if (item?.is_box_lunch) {
      const selectedItems = [];
      boxLunchCombo?.forEach((section, index) => {
        const selected = findSelectedItemAvailability(section.id);
        // Get selected Item
        const item = Item(selected, section.id);
        if (item) {
          selectedItems.push(item);
        }
        // Get selected category
      });

      return item?.is_single_item
        ? selectedItems.length === 1
          ? true
          : false
        : selectedItems.length === 2
        ? true
        : false;
    }
    if (item?.is_package) {
      return true;
    }
  };

  const MenuLinksHandler = (menu) => {
    history.replace(`${CONSTANT.ROUTE_MENU}/${menu.name}`, {
      showCustomization: true,
    });
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const [categoryScrolled, setCategoryScrolled] = useState(false);
  const [open, setOpen] = useState(false);
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const shouldHaveClass = scrollPosition > 20;
    setCategoryScrolled(shouldHaveClass);
  };
  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const handleGoBack = (): void => {
    history.goBack();
  };
  const getItemTotal = (itemPrice, size) => {
    //  ;
    let HalfSizeOrder = order;
    if (size == CONSTANT.HALF_SIZE.id) {
      HalfSizeOrder = {
        ...order,
        display_price: item.half_brink_item_price,
      };
    }
    return getCustomizedItemPrice(HalfSizeOrder, itemPrice);
  };
  const price = () => {
    let price = 0;
    // if (dbId) {
    //   price = order?.display_price;
    // } else {
    price =
      getItemTotal(item.price, CONSTANT.LARGE_SIZE.id) + order?.display_price;
    // }
    return price?.toFixed(2);
  };
  const showFooterButtonRef = useRef(null);
  const showFooterButton = useIsInViewport(showFooterButtonRef);
  useEffect(() => {
    addToOrderButtonShown && addToOrderButtonShown(showFooterButton);
  }, [showFooterButton]);

  function bucketKey(): number {
    if (item.is_single_item) {
      return CONSTANT.REQUIRED_SELECTED_SECTION_FOR_SINGLE_ITEM_BOX_LUNCHES;
    }
    if (item.is_box_lunch) {
      return CONSTANT.REQUIRED_SELECTED_SECTION_FOR_TWO_ITEM_BOX_LUNCHES;
    }
    if (item.is_package && item?.is_premium) {
      return CONSTANT.REQUIRED_AND_COMPLEMENTARY_SELECTED_SECTION_FOR_PREMIUM_PACKAGE;
    }
    if (item.is_package) {
      return CONSTANT.REQUIRED_AND_COMPLEMENTARY_SELECTED_SECTION_FOR_PACKAGE;
    }
    return SINGLE_ITEM_SELECTED_SECTION;
  }

  return (
    <>
      <div className={`${styles.menu_items_wrapper}`}>
        <div className={styles.tabsSearchWrap}>
          <Tab.Container id="left-tabs-example" activeKey={activeKey}>
            <Nav
              variant="pills"
              className={`${categoryScrolled ? styles.scroll_shadow_nav : ''}`}
            >
              {menus.map((menu, i: number) => (
                <Nav.Item id={menu.name} key={i}>
                  <Nav.Link
                    eventKey={menu.name}
                    onClick={() => MenuLinksHandler(menu)}
                  >
                    {menu.name}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Tab.Container>
        </div>
      </div>
      <Row className={styles.item_detail_menu}>
        {/* <div className="text-start pt-0">
          <button
            onClick={handleGoBack}
            className="btn btn-custom btn-custom-transparent back-arrow-btn f-s14 px-0 d-flex"
          >
            <span style={{ color: 'black' }}>Back</span>
          </button>
        </div> */}
        <ItemDetailImage
          path={awsAssetsFetcher(
            `Catering/Items/${item?.id}/item-${item?.id}`,
            'jpeg',
          )}
        />
        <Col xl="4" lg="5" sm="12">
          <ItemDescription item={item} isItemActive={isItemAvailable()} />
          {isSizeCardHide() && (
            <h6 className="f-s18 text-start lh-normal">
              <span className="d-inline-block">
                <strong
                  className={`font-Vcf pb-2 ${
                    price() > item.price && 'text-danger'
                  }`}
                >
                  Total: ${price()}
                </strong>
              </span>
              <hr className="mt-2 mb-4" />
            </h6>
          )}

          {isItemAvailable() && (
            <>
              {!item?.is_package && (
                <>
                  {isSizeCardHide() ? (
                    <ItemCard
                      handleSelection={() => {
                        togglePersonalize();
                      }}
                      order={order}
                      boxLunchCombo={boxLunchCombo}
                      findSelectedItemAvailability={
                        findSelectedItemAvailability
                      }
                      Item={Item}
                      isSingleItem={item.is_single_item}
                      Category={Category}
                      onRemoveClickHandler={onRemoveClickHandler}
                      onEditClickHandler={onEditClickHandler}
                      setEditFromDetails={setEditFromDetails}
                    />
                  ) : !item.is_tray_item && !item.half_brink_item_id ? (
                    <div>
                      <h6
                        className="f-s18 text-start lh-normal"
                        style={{
                          color: price() > item?.price ? 'darkred' : '',
                        }}
                      >
                        Total: $ {price()}
                      </h6>
                      <hr className="mt-1" />
                    </div>
                  ) : (
                    <ItemSize
                      item={item}
                      handleSelection={handleSelection}
                      order={order}
                      isSize={isSize}
                      price={price()}
                    />
                  )}
                </>
              )}
              {/* For Listing selected ingredients in create own salad */}
              {isCreateYourOwnSalad &&
              orderedIngredients?.length > 0 &&
              true ? (
                <ItemsBox
                  orderedIngredients={orderedIngredients}
                  isItMobile={isItMobile}
                  showModifierModal={showModifierModal}
                />
              ) : null}
              <div ref={autoScrollDivRef} />
              {!isCreateYourOwnSalad && (
                <RequiredListing
                  isPackege={!!item?.is_package}
                  ItemReqModifierGroup={item?.items_required_modifier_groups}
                  handleItemSelect={(currentItem) => {
                    setScrollToNextMissingSectionAndIgnoreSaladTrayItems(true);
                    handleSelect(currentItem);
                  }}
                  manualSelectedItem={manualSelectedItem}
                  manualSelectedItemId={manualSelectedItemId}
                  trays={item?.package_categories}
                  traysItemRequiredModifierGroup={
                    traysItemRequiredModifierGroup
                  }
                  handleIngredientSelection={handleIngredientSelection}
                  setIsRequiredModifierSelected={setIsRequiredModifierSelected}
                  isRequiredModifierSelected={isRequiredModifierSelected}
                  openItemInfoModal={openItemInfoModal}
                  bucketkey={bucketKey()}
                  isBoxLunch={item.is_box_lunch}
                  editCartItem={editCartItem}
                  requiredModifiersAtItemDetailSectionToHandleScroll={
                    requiredModifiersAtItemDetailSectionToHandleScroll
                  }
                  subItemRequiredModifiersAtItemDetailSectionToHandleScroll={
                    subItemRequiredModifiersAtItemDetailSectionToHandleScroll
                  }
                  requiredModifiersForDynamicSelectedItemAtItemDetailSectionToHandleScroll={
                    requiredModifiersForDynamicSelectedItemAtItemDetailSectionToHandleScroll
                  }
                  requiredItemForDynamicSelectedItemAtItemDetailSectionToHandleScroll={
                    requiredItemForDynamicSelectedItemAtItemDetailSectionToHandleScroll
                  }
                  setScrollToNextMissingSectionAndIgnoreSaladTrayItems={
                    setScrollToNextMissingSectionAndIgnoreSaladTrayItems
                  }
                />
              )}

              <ComplementaryItems
                itemFromApi={item}
                unrepeatedIngredients={unrepeatedIngredients}
                handleIngredientSelection={
                  handleComplementaryIngredientSelection
                }
                order={order}
                itemNo={
                  item?.is_box_lunch && !item?.is_single_item
                    ? COMPLEMENTARY_SELECTED_SECTION_FOR_TRY_TO_COMBO_ITEM
                    : COMPLEMENTARY_SELECTED_SECTION_FOR_SINGLE_ITEM
                }
                isItEdit={showCustomization}
                isSingleItemCustomization={true}
                titleshow={`pt-5`}
              />

              {/* <RecommendedItems /> */}

              <div className="pt-4" ref={orderNameFromRef}>
                <OrderNameForm
                  formState={formState}
                  handleFromFields={handleFromFields}
                  isBoxLunch={isBoxLunch}
                />
              </div>
            </>
          )}
          <div className={`pt-4`} ref={showFooterButtonRef}>
            {isItemAvailable() ? (
              <Button
                children={showCustomization ? 'UPDATE ORDER' : 'ADD TO ORDER'}
                className="w-100"
                loading={loading}
                onClick={() => addToOrderHandler()}
                disabled={
                  formState?.instructions.length > 150 ||
                  formState.quantity < 1 ||
                  formState.quantity > CONSTANT.MAX_BOX_LUNCH_QUANTITY
                }
              />
            ) : (
              <Button
                children="Unavailable"
                className="w-100"
                disabled={true}
              />
            )}
            <AddedModal
              showModal={showAddtoOrderModal}
              closeModal={() => {
                window.location.reload();
                setshowAddtoOrderModal(false);
              }}
              isCombo={item?.is_box_lunch ?? false}
              name={item?.name}
              item={item?.category_name}
              image={awsAssetsFetcher(
                `Catering/Items/${item?.id}/item-${item?.id}`,
                'jpeg',
              )}
            />
            {item?.full_item_short_description && item?.is_box_lunch ? (
              <ServingTextModal
                showModal={servingTextModal}
                closeModal={() => {
                  setServingTextModal(false);
                }}
                servingText={item?.full_item_short_description}
                itemLabel={item?.full_item_label}
              />
            ) : null}
          </div>
          {item?.is_customizable &&
            !isCreateYourOwnSalad &&
            isItemAvailable() && (
              <div className="pt-2">
                <Button
                  className="btn-large outline w-100"
                  onClick={customizeButtonHandler}
                >
                  customize
                </Button>
              </div>
            )}
        </Col>
      </Row>
      <div className="sticky-add-to-cart d-flex justify-content-center gap-0 flex-wrap gap-md-4 cateringStickyBtnWrap">
        <div className="pt-2 px-md-0 px-2">
          <Button
            type="button"
            className={`mw-100 btn-disabled-bg add-to-order-right ${
              item?.is_customizable ? '' : 'signleItem'
            }`}
            disabled={
              formState?.instructions.length > 150 ||
              formState.quantity < 1 ||
              formState.quantity > CONSTANT.MAX_BOX_LUNCH_QUANTITY
            }
            onClick={addToOrderHandler}
            loading={loading}
            LoaderPosition={`loader-position`}
          >
            {showCustomization ? 'UPDATE ORDER' : 'ADD TO ORDER'}
          </Button>
        </div>
        {item?.is_customizable &&
          !isCreateYourOwnSalad &&
          isItemAvailable() && (
            <div className="pt-2">
              <Button
                className="btn-large outline w-100"
                onClick={customizeButtonHandler}
              >
                customize
              </Button>
            </div>
          )}
      </div>
    </>
  );
};

export default ItemDetail;

const useAddToOrderPayloadStructure = (order, item, formState) => {
  const { name, quantity, instructions } = formState;

  const generateOrderStructure = (currentOrder) => {
    const itemObject = _clonedeep(currentOrder?.modifiers);
    if (item.is_box_lunch && !item.is_single_item) {
      itemObject.slice(-1);
    }
    const itemWithComplmentaryModifiers = creatingModifiersForItems(
      itemObject,
      1,
    );
    const structuredOrder: IAddToCartPayload = {
      item_id: currentOrder?.item_id,
      display_price: parseFloat(
        calculateItemPrice(currentOrder?.size, currentOrder?.display_price),
      ),
      item_size: itemSize(currentOrder?.size, item?.half_brink_item_id),
      name: currentOrder?.name,
      modifiers: [],
      item_calories: currentOrder?.item_calories,
      category_name: item?.category_name,
      make_time_minutes: item?.make_time_minutes,
      recipient_name: name.toString(),
      special_instructions: instructions,
    };
    itemWithComplmentaryModifiers?.map((currentObject, index: number) => {
      if (currentObject?.item) {
        // eslint-disable-next-line prefer-spread
        structuredOrder?.modifiers.push.apply(
          structuredOrder?.modifiers,
          currentObject?.modifiers,
        );
      } else {
        structuredOrder?.modifiers.push(currentObject);
      }
    });

    if (quantity) structuredOrder.quantity = Number(quantity);

    // BELOW CODE IS FOR ADDING NO DRESSING IN MODIFIERS DON"T REMOVE

    const selectedModifier = itemBuckets.getSingleBucket({
      name: REQUIRED_MODIFIERS,
      fromItem: SINGLE_ITEM_SELECTED_SECTION,
    });

    if (selectedModifier?.noDressings) {
      selectedModifier?.noDressings.forEach((dressingItem) => {
        if (dressingItem?.noDressing) {
          const noDressItem = item?.items_required_modifier_groups.find(
            (e) => e?.id === dressingItem.id,
          );
          const payload = {
            modifier_id: 0,
            modifier_name: noDressItem?.label,
            modifier_label: `No ${noDressItem?.label}`,
            display_price: 0,
            modifier_calories: 0,
            brink_id: 0,
            quantity: 0,
            modifier_group_min: 0,
            max: 0,
            type: SELECTED,
            code: Codes.NO,
            extendableLimitValue: 0,
            modifier_group_id: noDressItem?.id,
            modifier_group_max: noDressItem?.max,
            in_item: 0,
            in_slide: false,
            modifier_type: REQUIRED_MODIFIERS,
            additionalPrice: 0,
            is_no_modifier_selected: 1,
          };
          structuredOrder.modifiers.push(payload);
        }
      });
    }
    return structuredOrder;
  };

  const calculateItemPrice = (size: string, additionalPrice: number) => {
    let price = null;
    if (size === HALF_SIZE.id) {
      price = toFixedNumber(
        additionalPrice +
          getItemTotal(item?.half_brink_item_price, HALF_SIZE.id),
      );
    } else {
      price = toFixedNumber(
        additionalPrice + getItemTotal(item?.price, FULL_SIZE.id),
      );
    }
    return price;
  };

  const getItemTotal = (itemPrice, size) => {
    let HalfSizeOrder = order;
    if (size == HALF_SIZE.id) {
      HalfSizeOrder = {
        ...order,
        display_price: item.half_brink_item_price,
      };
    }
    return getCustomizedItemPrice(HalfSizeOrder, itemPrice);
  };

  return { generateOrderStructure };
};

const useGetRequiredModifierItems = (trays: ISubCategories[]) => {
  if (!trays)
    return {
      traysRequiredItem: [],
      traysItemRequiredModifierGroup: [],
    };
  const filteredTrays: any = trays?.filter(
    (tray: ISubCategories) =>
      tray.items.length === 1 &&
      tray.items[0].items_required_modifier_groups.length > 0,
  );
  const traysRequiredItem = filteredTrays.map((tray) => tray.items[0]);
  const traysItemRequiredModifierGroup = filteredTrays.flatMap((tray) => {
    const updatedData = addParentIdToRequiredModifierGroup(
      _clonedeep(tray.items[0].items_required_modifier_groups),
      tray,
    );
    return updatedData;
  });
  return {
    traysRequiredItem,
    traysItemRequiredModifierGroup: traysItemRequiredModifierGroup,
  };
};

const useShowCustomize = (data: {
  item;
  order;
  showCustomization: boolean;
  scrolling: {
    requiredModifiersAtItemDetailSectionToHandleScroll: IRefStatesForScrolling;
    subItemRequiredModifiersAtItemDetailSectionToHandleScroll: IRefStatesForScrolling;
    requiredModifiersForDynamicSelectedItemAtItemDetailSectionToHandleScroll: IRefStatesForScrolling;
    requiredItemForDynamicSelectedItemAtItemDetailSectionToHandleScroll: IRefStatesForScrolling;
    handleScrollToCustomisation: () => void;
    updateStateForNotSelectedSection: (type: string) => void;
  };
  currentItemRequiredModifierGroups;
  bucketKey: () => number;
  manualSelectedItem: ItemDetails;
  manualSelectedItemId: 2 | 3;
  scrollToNextMissingSectionAndIgnoreSaladTrayItems: boolean;
  setScrollToNextMissingSectionAndIgnoreSaladTrayItems: Dispatch<
    SetStateAction<boolean>
  >;
  orderNameFromRef;
}) => {
  const {
    item,
    order,
    showCustomization,
    scrolling,
    currentItemRequiredModifierGroups,
    bucketKey,
    manualSelectedItem,
    manualSelectedItemId,
    scrollToNextMissingSectionAndIgnoreSaladTrayItems,
    setScrollToNextMissingSectionAndIgnoreSaladTrayItems,
    orderNameFromRef,
  } = data;
  const AUTO_SELECTED_TRAYS = 'auto_selected_trays';
  const MANUAL_SELECTED_TRAYS_REQUIRED_MODIFIERS =
    'manual_selected_trays_required_modifier';
  const MANUAL_SELECTED_TRAY_ITEM = 'manual_selected_trays_item';
  const PARENT_ITEM_REQUIRED_MODIFIERS = 'parent_item_required_modifiers';

  const {
    requiredModifiersAtItemDetailSectionToHandleScroll,
    subItemRequiredModifiersAtItemDetailSectionToHandleScroll,
    requiredModifiersForDynamicSelectedItemAtItemDetailSectionToHandleScroll,
    requiredItemForDynamicSelectedItemAtItemDetailSectionToHandleScroll,
    handleScrollToCustomisation,
    updateStateForNotSelectedSection,
  } = scrolling;

  const onCustomizeClick = (type: string): boolean => {
    const sizeSelection = checkSizeSelection();
    if (!sizeSelection) {
      updateStateForNotSelectedSection(SIZE_NOT_SELECTED);
      return true;
    }
    if (item.is_package && !manualSelectedItem) {
      const requiredTrayItems = item.package_categories.filter(
        (tray: ISubCategories) => tray.items.length > 1,
      );
      if (checkRequiredSelection(requiredTrayItems, MANUAL_SELECTED_TRAY_ITEM))
        return true;
    }

    if (
      manualSelectedItem &&
      checkRequiredSelection(
        manualSelectedItem,
        MANUAL_SELECTED_TRAYS_REQUIRED_MODIFIERS,
      )
    ) {
      return true;
    }

    let items = item.is_package ? currentItemRequiredModifierGroups : item;

    if (checkRequiredSelection(items, AUTO_SELECTED_TRAYS)) return true;

    if (checkRequiredSelection(item, PARENT_ITEM_REQUIRED_MODIFIERS))
      return true;

    if (
      !scrollToNextMissingSectionAndIgnoreSaladTrayItems &&
      type === CUSTOMIZE &&
      handleScrollToCustomisation &&
      !isMobile
    ) {
      handleScrollToCustomisation();
    }

    return false;
  };

  const checkRequiredSelection = (
    items,
    type:
      | typeof AUTO_SELECTED_TRAYS
      | typeof MANUAL_SELECTED_TRAYS_REQUIRED_MODIFIERS
      | typeof PARENT_ITEM_REQUIRED_MODIFIERS
      | typeof MANUAL_SELECTED_TRAY_ITEM,
  ) => {
    let requiredModifierSelection: {
      status: boolean;
      missingGroupIndex: number;
    };
    switch (type) {
      case MANUAL_SELECTED_TRAY_ITEM:
        if (items) {
          for (let index = 0; index < items.length; index++) {
            const isSelected = order.modifiers.find(
              (currentItem) => currentItem.category_id === items[index].id,
            );
            if (!isSelected) {
              requiredModifierSelection = {
                status: false,
                missingGroupIndex: index,
              };
              startScrollingToMissingRequiredModifiers(
                requiredModifierSelection,
                requiredItemForDynamicSelectedItemAtItemDetailSectionToHandleScroll,
              );
              return true;
            }
          }
        }
        break;
      case AUTO_SELECTED_TRAYS:
        for (
          let index = 0;
          index < currentItemRequiredModifierGroups.length;
          index++
        ) {
          const requiredModifiersBucket: ISingleBucket =
            itemBuckets.getSingleBucket({
              name: REQUIRED_MODIFIERS,
              fromItem: bucketKeyForNonRequiredTray(
                order,
                currentItemRequiredModifierGroups[index].trayId,
              ),
            });
          requiredModifierSelection = checkIfAllRequiedSelected(
            requiredModifiersBucket,
            currentItemRequiredModifierGroups[index],
            index,
          );
          if (!requiredModifierSelection.status) {
            startScrollingToMissingRequiredModifiers(
              requiredModifierSelection,
              subItemRequiredModifiersAtItemDetailSectionToHandleScroll,
            );
            return true;
          }
        }
        break;
      case MANUAL_SELECTED_TRAYS_REQUIRED_MODIFIERS:
        for (
          let index = 0;
          index < items.items_required_modifier_groups.length;
          index++
        ) {
          const requiredModifiersBucket: ISingleBucket =
            itemBuckets.getSingleBucket({
              name: REQUIRED_MODIFIERS,
              fromItem: manualSelectedItemId,
            });
          requiredModifierSelection = checkIfAllRequiedSelected(
            requiredModifiersBucket,
            items.items_required_modifier_groups[index],
            index,
          );
          if (!requiredModifierSelection.status) {
            startScrollingToMissingRequiredModifiers(
              requiredModifierSelection,
              requiredModifiersForDynamicSelectedItemAtItemDetailSectionToHandleScroll,
            );
            return true;
          }
        }
        break;
      case PARENT_ITEM_REQUIRED_MODIFIERS:
        for (
          let index = 0;
          index < items.items_required_modifier_groups.length;
          index++
        ) {
          const requiredModifiersBucket: ISingleBucket =
            itemBuckets.getSingleBucket({
              name: REQUIRED_MODIFIERS,
              fromItem: bucketKey(),
            });
          requiredModifierSelection = checkIfAllRequiedSelected(
            requiredModifiersBucket,
            items.items_required_modifier_groups[index],
            index,
          );
          if (!requiredModifierSelection.status) {
            startScrollingToMissingRequiredModifiers(
              requiredModifierSelection,
              requiredModifiersAtItemDetailSectionToHandleScroll,
            );
            return true;
          }
        }
        break;
      default:
        break;
    }
  };

  const checkSizeSelection = (): boolean => {
    if (
      order?.size ||
      (showCustomization && order?.size && !item?.half_brink_item_id)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkIfAllRequiedSelected = (
    SelectedRequiredModifier,
    currentRequiredModifierGroups,
    index,
  ): {
    status: boolean;
    missingGroupIndex: number;
  } => {
    let minReached = null;
    const result: { status: boolean; missingGroupIndex: number } = {
      status: true,
      missingGroupIndex: null,
    };
    const isModifierExistFromCurrentGroup =
      SelectedRequiredModifier.additionalAdded.modifiers.filter(
        (modifier) =>
          modifier.modifier_group_id === currentRequiredModifierGroups.id,
      );
    if (item?.is_package && scrollToNextMissingSectionAndIgnoreSaladTrayItems) {
      const min =
        currentRequiredModifierGroups.min === 0
          ? 1
          : currentRequiredModifierGroups.min;
      minReached = isModifierExistFromCurrentGroup.length >= min;
    } else {
      minReached =
        isModifierExistFromCurrentGroup.length >=
        currentRequiredModifierGroups.min;
    }
    if (
      !minReached &&
      !SelectedRequiredModifier?.noDressings?.find(
        (modGroup) =>
          modGroup.id === currentRequiredModifierGroups.id &&
          modGroup.noDressing,
      )
    ) {
      result.status = false;
      result.missingGroupIndex = index;
    }
    return result;
  };

  const startScrollingToMissingRequiredModifiers = (
    orderButtonStatus,
    requiredModifiersRefToHandleScroll: {
      refToScrollToMissingRequiredModifiers: any[];
      isRequiredModifierSelected: {
        status: boolean;
        highLightArea: number;
      };
      setIsRequiredModifierSelected: React.Dispatch<
        React.SetStateAction<{ status: boolean; highLightArea: any }>
      >;
    },
  ) => {
    const {
      refToScrollToMissingRequiredModifiers,
      isRequiredModifierSelected,
      setIsRequiredModifierSelected,
    } = requiredModifiersRefToHandleScroll;
    setIsRequiredModifierSelected({
      status: orderButtonStatus.status,
      highLightArea: orderButtonStatus.missingGroupIndex,
    });
    setTimeout(() => {
      setIsRequiredModifierSelected({
        status: false,
        highLightArea: null,
      });
    }, 2000);
    for (let i = 0; i < refToScrollToMissingRequiredModifiers.length; i++) {
      if (i === orderButtonStatus.missingGroupIndex) {
        refToScrollToMissingRequiredModifiers[i].current?.scrollIntoView({
          behavior: 'smooth',
          block: `${isMobile ? 'center' : 'center'}`,
        });
        break;
      }
    }
  };

  return { onCustomizeClick };
};

const addParentIdToRequiredModifierGroup = (
  items_required_modifier_groups,
  parentItem,
) => {
  for (let index = 0; index < items_required_modifier_groups.length; index++) {
    items_required_modifier_groups[index].trayId = parentItem.id;
  }
  return items_required_modifier_groups;
};
