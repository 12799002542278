import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import Button from 'src/components/Button/Button';
import FormField from 'src/components/FormFields/FormField';
import {
  CARD_PLACEHOLDER,
  GIFT_CARD,
  GUEST_USER,
  ROUTE_ADD_PAYMENT,
} from 'src/constants';
import {
  getSuccessMessage,
  removePaymentCard,
  splitAmount,
} from 'src/helper/checkout/PaymentHelper';
import {
  convertMonth,
  getUserData,
  isGuestUser,
  validateCount,
} from 'src/helper/helperMethods';
import { Toast_Func } from 'src/helper/toast.helper';
import useAddPaymentMethod from 'src/hooks/useAddPaymentMethod';
import usePaymentMethods from 'src/hooks/usePaymentMethods';
import useUpdatePaymentMethod from 'src/hooks/useUpdatePaymentMethod';
import { PaymentRecordFields } from 'src/models/forms.model';
import { StoredPaymentMethod } from 'src/models/payment.model';
import useProfile from 'src/react-query-hooks/useProfile';
import {
  addGiftCard,
  addPaymentMethod,
  updateAmountOnSplit,
  updateGiftCard,
  updateIsSplitPayment,
  updatePaymentCard,
} from 'src/redux/slices/checkoutSlice';
import {
  addGiftCardForTextToPayFlow,
  addPaymentMethodForTextToPayFlow,
  updateAmountOnSplitForTextToPayFlow,
  updateGiftCardForTextToPayFlow,
  updateIsSplitPaymentForTextToPayFlow,
  updatePaymentMethodForTextToPayFlow,
} from 'src/redux/slices/addPayment/addPaymentSlice';
import { useAppDispatch, useAppSelector } from 'src/redux/store/store';
import { paymentRecordSchema } from 'src/validationSchemas/paymentRecordSchema';
import SplitPaymentMethod from './SplitPaymentMethod';
import StripePaymentElement from './StripePaymentElement';
import SplitPaymentToggle from './splitPaymentToggle';
import { useLocation } from 'react-router';
interface IVisitorPaymentModalProps {
  checkout?: any;
  addSplitPayment?: boolean;
  handleCloseEditPayment?: () => void;
  totalAmount: number;
  editPayment?;
  guestEditPayment?: StoredPaymentMethod;
  setOrderTippingAmount: (number) => void;
}
const VisitorPaymentModal = (props: IVisitorPaymentModalProps) => {
  const {
    handleCloseEditPayment,
    editPayment,
    guestEditPayment,
    addSplitPayment,
    totalAmount,
    checkout,
    setOrderTippingAmount,
  } = props;

  const location = useLocation();
  const handlePaymentForAddPaymentUser =
    location.pathname.includes(ROUTE_ADD_PAYMENT);

  const publishableKey = import.meta.env.VITE_REACT_APP_PUBLISHABLE_KEY;
  const stripePromise = loadStripe(publishableKey);

  const authInfo = handlePaymentForAddPaymentUser
    ? useAppSelector((state) => state.addPayment.addPaymentUser)
    : useAppSelector((state) => state.user.user);
  const splitAmounts = checkout.isSplitPayment;
  const [isSplitPayment, setIsSplitPayment] = useState(splitAmounts);
  const [showSplitContent, setShowSplitContent] = useState(false);
  const { data: paymentRecords = [], isFetching: paymentFetching } =
    usePaymentMethods(authInfo.id, authInfo.type);
  const [addNewPayment, setAddNewPayment] = useState<boolean>(
    guestEditPayment ? !guestEditPayment?.is_saved : paymentRecords?.length < 1,
  );
  const [savedPaymentMethods, setSavedPaymentMethods] = useState([]);
  const [submittingPayment, setSubmittingPayment] = useState<boolean>(false);
  const [selectPaymentMessage, setSelectPaymentMessage] = useState<string>('');
  const [newAddedPayment, setNewAddedPayment] = useState<any>(null);
  const setNewPayment = (payment) => {
    setNewAddedPayment(payment);
  };
  const checkoutDispatch = useAppDispatch();
  // const checkout = useAppSelector((state) => state.checkout);
  const paymentMethods = checkout.paymentMethods;
  let { mutateAsync: addPayment } = useAddPaymentMethod();
  let { mutateAsync: updatePaymentMethod } = useUpdatePaymentMethod();
  const { data: userProfileData } = useProfile(authInfo.id, authInfo.type);
  const userInfo = getUserData(authInfo, userProfileData);
  const guestUserInfo = JSON.parse(localStorage.getItem('CurrentUser'));
  let queryClient = useQueryClient();
  let editRecord: any;
  if (editPayment) {
    editRecord = {
      value: editPayment.name,
      label: `${editPayment.name} ending in ${editPayment.card.number}`,
      id: editPayment.id,
      is_default: editPayment.is_default,
      number: editPayment.card.number,
      valid: editPayment.card.exp_year,
    };
  }
  const initialFormState: PaymentRecordFields = {
    name: guestEditPayment?.name || '',
    postal_code: guestEditPayment?.postal_code || '',
    card: {
      number: guestEditPayment?.card
        ? `${CARD_PLACEHOLDER} ${guestEditPayment?.card.number}`
        : '',
      expiration: guestEditPayment?.card
        ? convertMonth(guestEditPayment?.card?.exp_month) +
          '/' +
          guestEditPayment?.card?.exp_year
        : '',
      cvc: guestEditPayment?.card.cvc || '',
      name: guestEditPayment?.card.name || '',
    },
    selected_payment:
      newAddedPayment ||
      editRecord ||
      savedPaymentMethods.find((payment) => payment.is_default),
    is_default: null,
    save_card: null,
  };
  const addNewPaymentMethod = () => {
    setAddNewPayment(true);
  };
  const replaceGiftCard = () => {
    if (checkout.giftCard.length > 0) {
      handlePaymentForAddPaymentUser
        ? checkoutDispatch(updateGiftCardForTextToPayFlow([]))
        : checkoutDispatch(updateGiftCard([]));
    }
  };
  const hideSplitPayments = () => {
    setShowSplitContent(false);
  };
  const saveSelectedPaymentMethod = (paymentMethod: any) => {
    if (editPayment || guestEditPayment) {
      updatePayments(guestEditPayment ? guestEditPayment.id : editPayment.id);
    }
    if (!isSplitPayment) {
      replaceGiftCard();
      if (paymentMethods.length > 0) {
        handlePaymentForAddPaymentUser
          ? checkoutDispatch(updatePaymentMethodForTextToPayFlow([]))
          : checkoutDispatch(updatePaymentCard([]));
      }
    }
    if (handlePaymentForAddPaymentUser) {
      checkoutDispatch(addPaymentMethodForTextToPayFlow(paymentMethod));
      checkoutDispatch(updateIsSplitPaymentForTextToPayFlow(isSplitPayment));
    } else {
      checkoutDispatch(addPaymentMethod(paymentMethod));
      checkoutDispatch(updateIsSplitPayment(isSplitPayment));
    }
  };

  const isEditForm = () => {
    return editPayment || guestEditPayment;
  };
  const handlePaymentSuccess = (paymentMethod: any, isSaved: boolean) => {
    if (
      paymentMethods.findIndex((card) => card.id == paymentMethod.id) < 0 ||
      isEditForm()
    ) {
      setNewAddedPayment({
        value: paymentMethod.name,
        label: `${paymentMethod.name} ending in ${paymentMethod.cardInfo.card.number}`,
        id: paymentMethod.id,
        is_default: paymentMethod.is_default,
        number: paymentMethod.cardInfo.card.number,
        valid: paymentMethod.cardInfo.card.exp_year,
      });
      if (!isSaved || isSplitPayment) saveSelectedPaymentMethod(paymentMethod);
      if (!editPayment && !guestEditPayment)
        Toast_Func({
          status: true,
          message: getSuccessMessage(editPayment || guestEditPayment),
        });
    } else {
      Toast_Func({ status: true, message: 'Payment Method already added' });
    }
    handleModalContentChange(isSaved);
  };

  const handPaymentError = (error: any) => {
    Toast_Func({ status: false, message: error.response.data.message });
    setSubmittingPayment(false);
  };

  const handleModalContentChange = (isSaved: boolean) => {
    if (isSplitPayment && !addSplitPayment) {
      setShowSplitContent(true);
    } else
      authInfo.id && isSaved
        ? setAddNewPayment(false)
        : handleCloseEditPayment();
  };
  const updatePayments = (editPaymentId: string | number) => {
    let updatedPaymentCards = removePaymentCard(paymentMethods, editPaymentId);
    handlePaymentForAddPaymentUser
      ? checkoutDispatch(
          updatePaymentMethodForTextToPayFlow(updatedPaymentCards),
        )
      : checkoutDispatch(updatePaymentCard(updatedPaymentCards));
  };
  const handleCheckoutNewPaymentSuccess = (data, is_saved) => {
    queryClient.invalidateQueries('paymentMethod');
    const paymentMethod = {
      id: data.id,
      name: data.name,
      number: `Card Ending in *${data.card.number}`,
      is_default: data.is_default,
      valid: `Valid Until ${data?.card.exp_month}/${data.card.exp_year}`,
      amount: splitAmount(checkout, totalAmount),
      is_saved: is_saved,
      cardInfo: data,
    };
    handlePaymentSuccess(paymentMethod, is_saved);
    setSubmittingPayment(false);
    handlePaymentForAddPaymentUser
      ? checkoutDispatch(
          updateAmountOnSplitForTextToPayFlow({
            totalAmount: Number(totalAmount),
          }),
        )
      : checkoutDispatch(
          updateAmountOnSplit({ totalAmount: Number(totalAmount) }),
        );
  };
  const handleCheckoutNewPaymentFailure = () => {
    Toast_Func({ status: false, message: 'Payment not added' });
  };
  const addNewCard = async (values: PaymentRecordFields, actions: any) => {
    let modifiedValues = {
      ...values,
      card: {
        exp_month: Number(values.card.expiration.split('/')[0]),
        exp_year: Number(values.card.expiration.split('/')[1]),
        number: values.card.number,
        cvc: values.card.cvc,
      },
      type: 'card',
      customer: {
        name: guestUserInfo.first_name + ' ' + guestUserInfo.last_name,
        email: guestUserInfo.email,
        id: guestUserInfo.id,
      },
      is_default: values.is_default ? true : false,
      is_saved: values.save_card ? true : false,
      customer_id: userInfo.id,
    };
    let mutate = guestEditPayment ? updatePaymentMethod : addPayment;
    await mutate(
      {
        newPaymentMethod: modifiedValues,
        ...(guestEditPayment && { recordID: guestEditPayment.id as string }),
      },
      {
        onSuccess: (data, variables, context) => {
          queryClient.invalidateQueries('paymentMethod');
          const paymentMethod = {
            id: data.id,
            name: data.name,
            number: `Card Ending in *${data.card.number}`,
            is_default: data.is_default,
            valid: `Valid Until ${data?.card.exp_month}/${data.card.exp_year}`,
            amount: splitAmount(checkout, totalAmount),
            is_saved: modifiedValues.is_saved,
            cardInfo: data,
          };
          handlePaymentSuccess(paymentMethod, values.save_card);
          setSubmittingPayment(false);
          handlePaymentForAddPaymentUser
            ? checkoutDispatch(
                updateAmountOnSplitForTextToPayFlow({
                  totalAmount: Number(totalAmount),
                }),
              )
            : checkoutDispatch(
                updateAmountOnSplit({ totalAmount: Number(totalAmount) }),
              );
        },
        onError: (error: any) => {
          handPaymentError(error);
          setSubmittingPayment(false);
        },
      },
    );
  };

  const handleGiftCardToggle = () => {
    if (
      !checkout?.giftCard.length &&
      checkout?.defaultGiftCard?.id &&
      !handlePaymentForAddPaymentUser
    ) {
      checkoutDispatch(
        addGiftCard({
          name: checkout?.defaultGiftCard.label,
          id: checkout?.defaultGiftCard.id,
          amount:
            checkout?.defaultGiftCard?.balance >= totalAmount
              ? totalAmount
              : checkout?.defaultGiftCard?.balance,
          type: GIFT_CARD,
          balance: checkout?.defaultGiftCard.balance,
        }),
      );
      checkoutDispatch(updateAmountOnSplit(totalAmount));
      checkoutDispatch(updateIsSplitPayment(true));
    }
    if (
      !checkout?.giftCard.length &&
      checkout?.defaultGiftCard?.id &&
      handlePaymentForAddPaymentUser
    ) {
      checkoutDispatch(
        addGiftCardForTextToPayFlow({
          name: checkout?.defaultGiftCard.label,
          id: checkout?.defaultGiftCard.id,
          amount:
            checkout?.defaultGiftCard?.balance >= totalAmount
              ? totalAmount
              : checkout?.defaultGiftCard?.balance,
          type: GIFT_CARD,
          balance: checkout?.defaultGiftCard.balance,
        }),
      );
      checkoutDispatch(updateAmountOnSplitForTextToPayFlow(totalAmount));
      checkoutDispatch(updateIsSplitPaymentForTextToPayFlow(true));
    }
  };
  const handleFormSubmission = async (values: PaymentRecordFields, actions) => {
    setSelectPaymentMessage('');
    if (!addNewPayment) {
      const selectedPayment = values.selected_payment;
      if (selectedPayment?.id) {
        if (
          paymentMethods.findIndex((card) => card.id == selectedPayment?.id) >=
            0 &&
          !isEditForm()
        )
          Toast_Func({ status: true, message: 'Payment Method already added' });
        else {
          setSubmittingPayment(true);
          const paymentMethod = {
            id: selectedPayment?.id,
            name: selectedPayment?.value,
            number: `Card Ending in *${selectedPayment?.number}`,
            valid: `Valid Until ${selectedPayment?.valid}`,
            amount: splitAmount(checkout, totalAmount),
          };
          saveSelectedPaymentMethod(paymentMethod);
          setSubmittingPayment(false);
          isSplitPayment && !addSplitPayment
            ? setShowSplitContent(true)
            : handleCloseEditPayment();
          handlePaymentForAddPaymentUser
            ? checkoutDispatch(
                updateAmountOnSplitForTextToPayFlow({
                  totalAmount: Number(totalAmount),
                }),
              )
            : checkoutDispatch(
                updateAmountOnSplit({ totalAmount: Number(totalAmount) }),
              );
        }
      } else {
        if (isSplitPayment && checkout?.giftCard?.length > 0) {
          handlePaymentForAddPaymentUser
            ? checkoutDispatch(
                updateIsSplitPaymentForTextToPayFlow(isSplitPayment),
              )
            : checkoutDispatch(updateIsSplitPayment(isSplitPayment));
          // setShowSplitContent(true);
          // return;
        }
        setSelectPaymentMessage('Kindly Select Payment Method');
      }
    } else {
      setSubmittingPayment(true);
      await addNewCard(values, actions);
    }
  };

  useEffect(() => {
    if (!isGuestUser(authInfo)) {
      if (paymentRecords?.length > 0) {
        const allPaymentsArray = paymentRecords.map((payment) => {
          return {
            value: payment.name,
            label: `${payment.name} ending in ${payment.card.number}`,
            id: payment.id,
            is_default: payment.is_default,
            number: payment.card.number,
            valid: payment.card.exp_year,
          };
        });
        setSavedPaymentMethods(allPaymentsArray);
      }
    }
  }, [paymentRecords]);

  return (
    <div>
      {!showSplitContent && (
        <div className="mx-auto full_width_mob">
          <h5 className="f-s22 font-Cls text-start mx-auto mb-2 text-capitalize mt-3 full_width_mob">
            {editPayment || guestEditPayment
              ? 'Edit Payment Method'
              : 'Add Payment Method'}
          </h5>
          <hr className="custom_hr_sty mt-0" />
          {authInfo.type != GUEST_USER && !addNewPayment ? (
            <Formik
              initialValues={initialFormState}
              onSubmit={handleFormSubmission}
              validationSchema={
                addNewPayment
                  ? paymentRecordSchema(Boolean(guestEditPayment))
                  : ''
              }
            >
              {({
                values,
                setFieldValue,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                submitForm,
              }) => (
                <form
                  className="new_form_design  my-0  delivery_location_select "
                  onSubmit={handleSubmit}
                >
                  {!addSplitPayment && totalAmount >= 2 ? (
                    <SplitPaymentToggle
                      setSplitToggle={setIsSplitPayment}
                      isSplitPayment={isSplitPayment}
                      handleSplitToggle={() => {
                        handleSubmit();
                        handleGiftCardToggle();
                      }}
                      defaultCard={checkout?.defaultGiftCard}
                    />
                  ) : null}
                  {/* login user  */}
                  <>
                    <FormField
                      labelText={'Saved Payment methods'}
                      options={savedPaymentMethods}
                      name="selected_payment"
                      errors={errors}
                      touched={touched}
                      type="select"
                      value={values.selected_payment}
                      onChange={(payment) => {
                        setSelectPaymentMessage('');
                        setFieldValue('selected_payment', payment);
                      }}
                      placeholder={'Select Payment Method'}
                      inputFieldClass={'custom_select mb-0'}
                    />
                    <span className={'clr-dark-red f-s11'}>
                      {selectPaymentMessage}
                    </span>
                    <div className="d-flex justify-start-start my-3">
                      <p
                        className={` p-0 f-w6 p-16 clr-dark-red font-Vcf mb-0 f-s16 cursor-pointer`}
                        onClick={() => {
                          if (validateCount(savedPaymentMethods))
                            addNewPaymentMethod();
                        }}
                      >
                        Add a New Payment Method
                      </p>
                    </div>
                    <hr className="custom_hr_sty " />
                    <div className="d-flex justify-content-center my-3">
                      <Button
                        type="button"
                        className="btn-large py-2 f-s16 my-3"
                        onClick={() => {
                          submitForm();
                        }}
                        disabled={submittingPayment}
                        loading={submittingPayment}
                      >
                        {!addSplitPayment
                          ? 'Confirm Payment Method'
                          : 'Continue'}
                      </Button>
                    </div>
                  </>
                </form>
              )}
            </Formik>
          ) : (
            <>
              {!addSplitPayment && totalAmount >= 2 ? (
                <SplitPaymentToggle
                  setSplitToggle={setIsSplitPayment}
                  isSplitPayment={isSplitPayment}
                  handleSplitToggle={handleGiftCardToggle}
                  defaultCard={checkout?.defaultGiftCard}
                />
              ) : null}
              {/* <NewCheckoutPaymentForm values={values} setFieldValue={setFieldValue} errors={errors} touched={touched} handleChange={handleChange} isEditPayment={Boolean(guestEditPayment)} isGuestUser={authInfo.type === GUEST_USER} /> */}
              <Elements stripe={stripePromise}>
                <StripePaymentElement
                  isCheckout={true}
                  handleCheckoutNewPaymentFailure={
                    handleCheckoutNewPaymentFailure
                  }
                  handleCheckoutNewPaymentSuccess={
                    handleCheckoutNewPaymentSuccess
                  }
                  handlePaymentForAddPaymentUser={
                    handlePaymentForAddPaymentUser
                  }
                />
              </Elements>
            </>
          )}
        </div>
      )}

      {showSplitContent && (
        <SplitPaymentMethod
          setOrderTippingAmount={setOrderTippingAmount}
          backBTn={'Payment Method'}
          backBtnHiden={null}
          checkout={checkout}
          totalAmount={totalAmount}
          handleCloseSplitPayment={handleCloseEditPayment}
          hideSplitPayments={hideSplitPayments}
        />
      )}
    </div>
  );
};

export default VisitorPaymentModal;
