import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import { getUser } from 'src/helper/helperMethods';
import * as CONSTANT from '../../../constants';
import { s3BaseUrl } from '../../../constants';
import useRedeemOffer from '../../../hooks/useRedeemOffer';
import { IOfferItem } from '../../../models/rewards.model';
import { useAppSelector } from '../../../redux/store/store';

import RedeemDollarsModal from 'src/Features/Rewards/Redeem/redeemDollarsModal';
import CustomModal from 'src/components/customModal/CustomModal';
import OfferEligibilityModal from './OfferEligibilityModal';
import styleClasses from './offer.module.scss';

interface IOfferItemCardProps {
  offerItem: IOfferItem;
  selectedOfferItem: IOfferItem;
  btnText: string;
  subTotal: number;
}

const OfferItemCard: React.FC<IOfferItemCardProps> = ({
  offerItem,
  selectedOfferItem,
  btnText,
  subTotal,
}) => {
  const { name, image_url, in_cart, redirect_item_id } = offerItem;
  const [offerEligibilityModal, setOfferEligibilityModal] = useState<{
    show: boolean;
    failedOfferCriteria: string;
  }>({
    show: false,
    failedOfferCriteria: '',
  });
  const cartItems = useAppSelector((state) => state.cart.items);
  const { redeemOffer } = useRedeemOffer();
  const [showRedeemModal, setShowRedeemModal] = useState(false);
  const redeemOfferItem = () => {
    const { showModalOrCart, failedOfferCriteria } = redeemOffer({
      offerItem,
      cartItems,
      selectedOfferItem,
      subTotal,
      isOfferClicked: true,
    });
    if (
      showModalOrCart &&
      failedOfferCriteria === CONSTANT.OFFER_IN_STORE_REDEMPTION
    ) {
      setShowRedeemModal(true);
      return;
    }

    if (showModalOrCart) {
      setOfferEligibilityModal({
        show: true,
        failedOfferCriteria,
      });
    }
  };
  const { loyalty_user_id } = getUser();
  const closeShowRedeemModal = () => {
    setShowRedeemModal(false);
  };

  return (
    <>
      <OfferEligibilityModal
        show={offerEligibilityModal.show}
        offerFailedCriteria={offerEligibilityModal.failedOfferCriteria}
        offerDetails={offerItem.data}
        closeModal={() =>
          setOfferEligibilityModal({
            show: false,
            failedOfferCriteria: '',
          })
        }
      />
      <Col sm="4" xs="4" className={styleClasses.suggestion_column}>
        <div
          className={`${styleClasses.other_products_item} text-center ${
            btnText === 'Redeemed' && styleClasses.active
          }`}
        >
          <div className={styleClasses.cart_img_wrap + ' mb-3'}>
            <img
              src={
                image_url
                  ? image_url
                  : `${s3BaseUrl}/Items/${redirect_item_id}/item-${redirect_item_id}.webp`
              }
              className="img-fluid"
              alt={name}
            />
          </div>
          <h5 className={`f-s14 f-sm-s11 lh-18 font-rale f-w5`}>{name}</h5>
          <button
            type="button"
            className={`f-sm-s12 ${styleClasses.itemSelectBtn} ${
              !in_cart && 'outline'
            } mb-2`}
            onClick={() => {
              redeemOfferItem();
            }}
          >
            {btnText}
          </button>
        </div>
      </Col>
      <CustomModal
        showModal={showRedeemModal}
        closeModal={closeShowRedeemModal}
        title={''}
      >
        <RedeemDollarsModal
          header={offerItem?.name}
          title={offerItem?.expiration}
          description={'Scan this code at the counter to redeem your offer'}
          qrCodeValue={loyalty_user_id}
        />
      </CustomModal>
    </>
  );
};

export default OfferItemCard;
