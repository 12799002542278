/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react';
import { Container, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'src/components/Button/Button';
import CustomModal from 'src/components/customModal/CustomModal';
import AddBoxLunchIngredients from 'src/containers/Modifiers/AddBoxLunchIngredients';
import AddIngredients from 'src/containers/Modifiers/AddIngredients';

import {
  CREATE_YOUR_OWN_SALAD_TRAY,
  REQUIRED_MODIFIERS,
  TRY_TO_COMBO_ITEM,
  BOX_LUNCH_SELECTED_SECTION_FIRST_SECTION,
} from '../../../constants';
import styleClasses from '../../../containers/Modifiers/try2.module.scss';
import {
  IModifiersObjects,
  ITry2Customization,
} from '../../../models/try2Combo';
import { itemBuckets } from '../../../priceCalculation/buckets';
import { modifiersAgainstAnItem } from '../../../priceCalculation/helper';
import {
  removeTemporarilyAddedPriceForItem,
  updateSpecificItemSelectedModifiers,
} from '../../../redux/slices/itemCustomizationSlice';

import 'swiper/css/bundle';

const BoxLunchesCustomization: React.FunctionComponent<any> = (props) => {
  let {
    showCustomizationModal,
    closeCustomizationModal,
    modifierGroups,
    handleIngredientSelection,
    coreModifiers,
    requiredModifiers,
    addItemtoComboClick,
    handleSyncIngredients,
    selectedItem,
    sectionSelected,
    removeSelectedIngredients,
    currentEditItem,
    noDressingHandler,
    itemName,
    isItEdit = false,
    styles,
    setSelectedItem,
    Item,
  } = props;

  const order = useSelector((state: any) => state.itemCustomization);
  const dispatch = useDispatch();
  const [disableApply, setDisableApply] = useState<boolean>(false);
  const [selectedCustomizeData, setSelectedCustomizeData] = useState<
    IModifiersObjects[]
  >([]);
  const addSelectedModifiers = (selectedModifier, commulativeItems): void => {
    selectedModifier?.modifiers.forEach((element) => {
      const index = commulativeItems.findIndex(
        (e) => e.groupId === element?.modifier_group_id,
      );
      if (index !== -1) {
        commulativeItems[index].quantity =
          commulativeItems[index].quantity + element?.quantity;
        commulativeItems[index].noDressing = false;
      } else {
        const trackObj = {
          groupId: element?.modifier_group_id,
          quantity: element?.quantity,
          noDressing: false,
        };
        commulativeItems.push(trackObj);
      }
    });
  };

  const addNoDressings = (selectedModifier, commulativeItems): void => {
    selectedModifier?.noDressings.forEach((element) => {
      const index = commulativeItems.findIndex(
        (e) => e.groupId === element?.id,
      );
      if (index === -1) {
        const trackObj = {
          groupId: element?.id,
          quantity: 0,
          noDressing: element?.noDressing,
        };
        commulativeItems.push(trackObj);
      } else {
        commulativeItems[index].noDressing = element?.noDressing;
      }
    });
  };

  const getOrderButtonState = () => {
    let activeItem = null;
    if (currentEditItem) {
      activeItem = currentEditItem;
    } else {
      currentEditItem = sectionSelected;
    }
    if (requiredModifiers) {
      const selectedModifier = itemBuckets.getSingleBucket({
        name: REQUIRED_MODIFIERS,
        fromItem: parseInt(activeItem),
      });
      const commulativeItems = [];
      if (!requiredModifiers.length) {
        setDisableApply(false);
        return;
      }
      addSelectedModifiers(selectedModifier, commulativeItems);

      if (selectedModifier?.noDressings) {
        addNoDressings(selectedModifier, commulativeItems);
      }

      let isMinExeceeded = null;
      for (let i = 0; i < requiredModifiers.length; i++) {
        const itemIndex = commulativeItems.findIndex(
          (e) => e.groupId === requiredModifiers[i].id,
        );
        const minReached =
          commulativeItems[itemIndex]?.noDressing === false
            ? commulativeItems[itemIndex]?.quantity > 0 &&
              commulativeItems[itemIndex]?.quantity >= requiredModifiers[i].min
            : true;
        if (itemIndex === -1 || !minReached) {
          isMinExeceeded = false;
          break;
        } else {
          isMinExeceeded = true;
        }
      }

      if (isMinExeceeded) {
        setDisableApply(false);
      } else {
        setDisableApply(true);
      }
    } else {
      setDisableApply(true);
    }
  };

  useEffect(() => {
    getOrderButtonState();
  }, [order]);

  const updateSelectedModifiers = (modifier: IModifiersObjects) => {
    updateSelectedCustomizeData(modifier);
    handleIngredientSelection(modifier);
  };

  const addOldQuantityToModifier = (quantity: number, index: number) => {
    const exist = selectedCustomizeData[index];
    if (index === -1 || typeof exist?.oldQuantity === 'undefined') {
      if (quantity) {
        return quantity;
      } else {
        return 0;
      }
    } else {
      return exist.oldQuantity;
    }
  };

  const updateSelectedCustomizeData = (modifier: IModifiersObjects) => {
    let quantity = null;
    if (modifier?.quantity) {
      const item = order?.modifiers.find(
        (item) => item.item === currentEditItem,
      );
      const updateModifier = item?.modifiers.find(
        (currentModifier) =>
          currentModifier?.modifier_id === modifier.modifier_id,
      );
      quantity = updateModifier?.quantity;
    }
    const index = selectedCustomizeData?.findIndex(
      (selectedModifier) =>
        selectedModifier.modifier_id === modifier.modifier_id,
    );
    modifier.oldQuantity = addOldQuantityToModifier(quantity, index);
    if (index !== -1) {
      selectedCustomizeData[index] = modifier;
      setSelectedCustomizeData([...selectedCustomizeData]);
    } else {
      setSelectedCustomizeData([...selectedCustomizeData, modifier]);
    }
  };

  const addSelectedIngredients = () => {
    closeCustomizationModal(false);
    addItemtoComboClick(selectedItem);
  };

  const closeModal = () => {
    const itemId = currentEditItem ? currentEditItem : sectionSelected;
    removeSelectedIngredients(selectedCustomizeData, sectionSelected);
    closeCustomizationModal(true);
    dispatch(
      removeTemporarilyAddedPriceForItem({
        price: itemBuckets.discardBucketChanges(itemId),
        item: itemId,
      }),
    );
    dispatch(
      updateSpecificItemSelectedModifiers({
        itemModifiers: modifiersAgainstAnItem({
          itemId: BOX_LUNCH_SELECTED_SECTION_FIRST_SECTION,
        }),
        itemId: '1',
      }),
    );
    setSelectedItem('');
  };

  const isItCreateYourOwnSalad =
    itemName?.toLowerCase() === CREATE_YOUR_OWN_SALAD_TRAY.toLowerCase();

  return (
    <CustomModal
      showModal={showCustomizationModal}
      closeModal={closeModal}
      title={''}
      size="lg"
      modalBodyClass={styles.boxCustomizeModal}
      modalDialogClasses={styles.boxCustomizationWraper}
      scrollable={true}
    >
      {isItCreateYourOwnSalad && (
        <h2 className="font-Cls f-s22 ls-normal lh-normal text-center py-2 d-md-none">
          {itemName}
        </h2>
      )}
      <div className={`${styleClasses.items_wrap} mb-4 mb-sm-5 py-0 px-md-0`}>
        <AddBoxLunchIngredients
          modifierGroups={modifierGroups}
          coreModifiers={coreModifiers}
          requiredModifiers={requiredModifiers}
          handleIngredientSelection={(data: IModifiersObjects) =>
            updateSelectedModifiers(data)
          }
          selectedItem={selectedItem}
          handleSyncIngredients={handleSyncIngredients}
          sectionSelected={currentEditItem}
          noDressingHandler={noDressingHandler}
          itemName={itemName}
          isItEdit={isItEdit}
          Item={Item}
          isCYOSColSize={'col-lg-3'}
        />
        <Button
          disabled={disableApply}
          onClick={addSelectedIngredients}
          className="text-center d-block m-auto mt-4"
        >
          Apply
        </Button>
      </div>
    </CustomModal>
  );
};
export default BoxLunchesCustomization;
