import { FC, useEffect, useRef, useState } from 'react';
import TagSymbol from 'src/components/Molecules/Symbols/Symbol';
import { ItemDetails } from 'src/models/item.model';
import { awsAssetsFetcher } from 'src/utils/awsAssetsFetcher';

import styles from '../menu.module.scss';
import IMenuItems from '../ItemGrid/Types';
import { ItemDetailsModal } from '../ItemGrid/ItemDetailsModal';
interface NavProps {
  item: ItemDetails;
}
const ItemInfoModal: FC<NavProps> = ({ item }) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const [itemDetails, setItemDetails] = useState<IMenuItems>({} as IMenuItems);
  const [divHeight, setDivHeight] = useState<number>(0);
  const itemHeight = useRef(null);

  useEffect(() => {
    if ((!divHeight || divHeight <= 0) && itemHeight?.current?.scrollHeight) {
      setDivHeight(itemHeight?.current?.scrollHeight);
    }
  }, [itemHeight?.current?.scrollHeight]);

  const showDetailsHandler = (e: React.MouseEvent<HTMLButtonElement>, item) => {
    setShowMore(true);
    setItemDetails(item);
    e.stopPropagation();
  };

  const closeDetailsModal = () => {
    setShowMore(false);
  };

  return (
    <>
      {item ? (
        <>
          <h3 className="f-s22 font-Cls text-center mt-2">{item.name}</h3>
          <div className={`${styles.item_grid_wraper} shadow-none pb-4`}>
            <div
              className={`px-0 pb-1 position-relative ${styles.item_grid_col}`}
            >
              <div className={`pt-0 pt-md-5 ${styles.cardItemImg}`}>
                <img
                  src={awsAssetsFetcher(
                    `Catering/Items/${item?.id}/item-${item?.id}`,
                    'jpeg',
                  )}
                  alt="item image"
                />
              </div>
              <div className="d-flex align-items-start flex-column pb-4 pt-4">
                <TagSymbol tags={item.tags} />
              </div>
              <p
                className={`f-s16 f-sm-s16 ${styles.itemDescriptionText}`}
                ref={itemHeight}
              >
                {item.description}
              </p>
              <p className={`${styles.showMore} text-end p-16`}>
                {divHeight > 58 && (
                  <button
                    type="button"
                    className={`${styles.item_subtitle_button} btn-custom p-0 f-w6 p-16`}
                    onClick={(e) => showDetailsHandler(e, item)}
                  >
                    Show more
                  </button>
                )}
              </p>
            </div>
          </div>
          <ItemDetailsModal
            showModal={showMore}
            closeModal={closeDetailsModal}
            itemDetials={itemDetails}
          />
        </>
      ) : undefined}
    </>
  );
};

export default ItemInfoModal;
