import { useMutation } from 'react-query';

import { paymentService } from '../services';

const addPaymentMethod = async (newPaymentMethod: any) => {
  const response = await paymentService.addPaymentMethod(newPaymentMethod);
  return response.data.data;
};

export default function useAddPaymentMethod() {
  return useMutation((data: { newPaymentMethod: any }) =>
    addPaymentMethod(data.newPaymentMethod),
  );
}
