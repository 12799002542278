/* eslint-disable prefer-const */
import _clonedeep from 'lodash.clonedeep';

import * as CONSTANT from '../../constants';
import {
  COMBO_TYPE_IS_KIDS_COMBO,
  COMBO_TYPE_IS_TRY_2_COMBO,
} from '../../constants';
import { Codes } from '../../models/item.model';
import { itemBuckets } from '../../priceCalculation/buckets';
import { creatingModifiersForItems } from '../helperMethods';

interface IFinalOrder {
  order: any;
  category_id: number;
  noOfItems: number;
  comboType: string;
  items: any;
  totalSelectedSection: number;
}

export const finalOrder: React.FC<IFinalOrder> = (props) => {
  const {
    order,
    category_id,
    noOfItems,
    comboType,
    items,
    totalSelectedSection,
  } = props;
  const clonedOrder = _clonedeep(order);
  const {
    item_id,
    name,
    display_price,
    original_price,
    item_calories,
    brink_id,
  } = clonedOrder;
  let { modifiers } = clonedOrder;
  modifiers = creatingModifiersForItems(modifiers, noOfItems);
  const payload: any = {
    item_size: order.size,
    item_id,
    name,
    display_price,
    price: display_price,
    original_price,
    item_calories,
    modifiers,
    brink_id,
    category_id,
    id: item_id,
    free: false,
  };
  if (comboType === COMBO_TYPE_IS_TRY_2_COMBO) payload.isTry2Combo = true;

  //ADDI NG NO DRESSING IN MODIFIERS
  if (
    comboType === COMBO_TYPE_IS_TRY_2_COMBO ||
    comboType === CONSTANT.COMBO_TYPE_PACKAGES
  ) {
    for (let i = 0; i < totalSelectedSection; i++) {
      const selectedModifier = itemBuckets.getSingleBucket({
        name: CONSTANT.REQUIRED_MODIFIERS,
        fromItem: i,
        isFromItemComingAsAnIndex: true,
      });
      if (selectedModifier?.noDressings) {
        let category: any = [];
        if (comboType === COMBO_TYPE_IS_TRY_2_COMBO) {
          category = items?.sub_categories.find(
            (category) => category.id === modifiers[i].category_id,
          );
        }
        if (comboType === CONSTANT.COMBO_TYPE_PACKAGES) {
          category = items?.package_categories.find(
            (category) => category.id === modifiers[i].category_id,
          );
        }
        const item = category.items.find(
          (categoryItem) => categoryItem.id === modifiers[i].modifier_id,
        );
        selectedModifier?.noDressings.forEach((dressingItem) => {
          if (dressingItem?.noDressing) {
            const noDressItem = item?.items_required_modifier_groups.find(
              (e) => e?.id === dressingItem.id,
            );
            const noDressingPayload = {
              modifier_id: 0,
              item_id: 0,
              modifier_name: noDressItem?.label,
              modifier_label: `No ${noDressItem?.label}`,
              display_price: 0,
              modifier_calories: 0,
              brink_id: 0,
              quantity: 0,
              modifier_group_min: 0,
              max: 0,
              type: CONSTANT.SELECTED,
              code: Codes.NO,
              extendableLimitValue: 0,
              modifier_group_id: noDressItem?.id,
              modifier_group_max: noDressItem?.max,
              in_item: 0,
              in_slide: false,
              modifier_type: CONSTANT.REQUIRED_MODIFIERS,
              additionalPrice: 0,
              is_no_modifier_selected: 1,
            };
            if (!payload.modifiers[i].modifiers)
              payload.modifiers[i].modifiers = [];
            payload.modifiers[i].modifiers.push(noDressingPayload);
          }
        });
      }
    }
  }

  return payload;
};
