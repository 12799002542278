import React from 'react';
import { Row } from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';

import { IOfferItem } from '../../../models/rewards.model';

import styleClasses from './offer.module.scss';
import OfferItemCard from './OfferItemCard';

interface IOfferItemListProps {
  offers: IOfferItem[];
  selectedOfferItem: IOfferItem;
  title: string;
  subTotal: number;
}

const OfferItemsList: React.FC<IOfferItemListProps> = (props) => {
  const { offers, selectedOfferItem, title, subTotal } = props;
  const getBtnText = (reward) => {
    return reward.in_cart ? 'Redeemed' : 'Redeem';
  };
  if (!offers?.length) return null;

  return (
    <>
      <div className={styleClasses.other_products_wrap}>
        <h4 className="f-s22 f-sm-s16 font-Cls lh-normal ls-normal mb-3">
          {title}
        </h4>
        <Row className={styleClasses.suggestion_row + ' flex-nowrap pt-1 '}>
          <Scrollbars
            className={styleClasses.offers_scrolbar_height}
            renderThumbHorizontal={(props) => (
              <div {...props} className={styleClasses.Scrollbar_thumb} />
            )}
            renderView={(props) => (
              <div {...props} className={styleClasses.Scrollbar_view} />
            )}
          >
            {offers.map((offerItem: IOfferItem, index: number) => {
              return (
                <OfferItemCard
                  key={index}
                  offerItem={offerItem}
                  selectedOfferItem={selectedOfferItem}
                  btnText={getBtnText(offerItem)}
                  subTotal={subTotal}
                />
              );
            })}
          </Scrollbars>
        </Row>
      </div>
      <div className="shadow-divider"></div>
    </>
  );
};

export default OfferItemsList;
