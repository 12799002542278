import React from 'react';
import Bugsnag, { BreadcrumbType } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import { APP_VERSION } from '../constants';

import { getUser } from './helperMethods';

const BugSnagTypes = { STATE: 'state', ERROR: 'error' };

export const bugSnagInititalize = () => {
  const isEnabledReleaseStage = Boolean(
    import.meta.env.VITE_REACT_APP_BUG_SNAG_IS_ENABLED_RELEASE_STAGE,
  );
  const enabledReleaseStages = isEnabledReleaseStage
    ? [import.meta.env.VITE_REACT_APP_ENV]
    : [];

  Bugsnag.start({
    apiKey: isEnabledReleaseStage
      ? import.meta.env.VITE_REACT_APP_BUG_SNAG_API_KEY
      : 'noAPIKeyNeeded',
    plugins: [new BugsnagPluginReact()],
    onError: (event) => {
      const user = getUser();
      event.setUser(user.id, user.email, user.full_name);
      const errorClass = event?.errors[0]?.errorClass;
      const errorMessage = event?.errors[0]?.errorMessage;
      // Check for the chunk error class or message
      if (errorClass === 'ChunkLoadError' || errorMessage.includes('Loading CSS chunk') ||
        errorMessage?.includes("Failed to load Stripe.js")
      ) {
        return false; // Prevent Bugsnag from reporting this error
      }
    },
    enabledReleaseStages,
    releaseStage: import.meta.env.VITE_REACT_APP_ENV,
    appVersion: APP_VERSION,
  });
  return Bugsnag.getPlugin('react').createErrorBoundary(React);
};

export const bugSnagNotifyAPIError = (
  apiName: string,
  requestPayload: object,
  responseErrorMessage: string,
) => {
  Bugsnag.leaveBreadcrumb(
    `Failed API: ${apiName}`,
    requestPayload,
    BugSnagTypes.ERROR as BreadcrumbType,
  );
  Bugsnag.notify(responseErrorMessage);
};
